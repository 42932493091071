// import './bootstrap';

import { createApp } from "vue/dist/vue.esm-bundler";
import Home from "./components/home/HomeComponent.vue";
import Profile from "./components/ProfileComponent.vue";
import Users from "./components/administration/settings/UsersComponent.vue"
import Roles from "./components/administration/settings/RolesAndPermissionsComponent.vue"
import Register from "./components/administration/settings/RegisterComponent.vue";

import Permissions from './mixins/Permissions.vue';
import FormatDate from './mixins/FormatDate.vue';
import Notifications from '@kyvg/vue3-notification'

//Analytics
// import Sales from './components/analytics/sales/SalesComponent.vue'
// import SalesFactMarka from './components/analytics/sales/SalesFactMarkaComponent.vue'
import SalesFact from './components/analytics/sales/SalesFactComponent.vue'
import PriceManagement from "./components/analytics/price/PriceManagementComponent.vue";
import FinishedGoodsWarehouse from "./components/analytics/warehouse/FinishedGoodsWarehouseComponent.vue";

import VueApexCharts from "vue3-apexcharts";
import Scroll from "./mixins/Scroll.vue";
import Preloader from "./mixins/Preloader.vue";
import 'vue-select/dist/vue-select.css';
import ViliyaPark from "./components/analytics/ViliyaParkComponent.vue";
import KPI from "./components/analytics/KPIComponent.vue";
import PersonalAccounts from "./components/analytics/funds/PersonalAccountsComponent.vue";
import Statement from "./components/analytics/funds/StatementComponent.vue";
import Funds from "./components/analytics/funds/FundsComponent.vue";
import SM from "./components/analytics/funds/SMComponent.vue";
import Currency from "./mixins/Currency.vue";


import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';


const app = createApp({
    components:{
        Home,
        Profile,
        Users,
        Roles,
        Register,

//      Analytics
//         Sales,
//         SalesFactMarka,
        SalesFact,

        PriceManagement,
        FinishedGoodsWarehouse,
        ViliyaPark,
        KPI,
        PersonalAccounts,
        Statement,
        Funds,
        SM,
    }
});

app.use(Notifications)
app.use(VueApexCharts)
app.use(VCalendar)
app.mixin(Permissions);
app.mixin(FormatDate);
app.mixin(Preloader);
app.mixin(Scroll);
app.mixin(Currency);
app.mount('#app');
