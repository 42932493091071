<template>
    <div class="row">
        <div class="col-12">
            <!-- Left sidebar -->
            <div class="email-leftbar card px-3">
                <button @click="settings(CREATE)" type="button" class="btn btn-danger btn-block waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".create-component">
                    Настройки
                </button>
                <button @click="settings(CREATE_PERMISSION)" type="button" class="btn btn-info btn-block waves-effect waves-light mt-2" data-bs-toggle="modal" data-bs-target=".create-permission-component">
                    Создать разрешение
                </button>
                <h6 class="mt-5">РОЛИ ПОЛЬЗОВАТЕЛЕЙ</h6>
                <div class="mail-list">
                    <a v-for="role in roles" @click.prevent="settings(UPDATE_ROLE_USERS,role)" href="" ><i class="mdi mdi-account-tie-voice me-2"></i>{{role.name}}
                        <span v-show="role.name !== 'JEDI-MASTER' && role.name !== 'USER'" @click="deleteRole(role)" class="ms-1 float-end"><i class="mdi mdi-trash-can-outline me-2"></i></span></a>
                </div><!--class="active" -->

            </div>
            <!-- End Left sidebar -->

            <!-- Right Sidebar -->
            <div class="email-rightbar mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex mb-4">
                            <div class="flex-1">
                                <h5 class="font-size-14 my-1">РАЗРЕЩЕНИЯ ПОЛЬЗОВАТЕЛЕЙ</h5>
                                <small class="text-muted">COSWiCK Solid Hardwood Floors.</small>
                            </div>
                        </div>

                        <div v-for="section in sections">
                        <h4 class="card-title">{{section.name}}</h4>
                            <div  v-for="group in groups">
                                <p class="card-title-desc mt-3 mb-1 text-warning">- {{group.name}}</p>
                                <div v-for="permission in permissions" v-show="group.id === permission.group_id && permission.section_id === section.id" class="form-check form-switch mb-3" dir="ltr">
                                    <input type="checkbox" class="form-check-input" @change="checkAssignPermission(permission.id)" v-model="checkbox_permission" :id="'for-'+permission.id" :value="permission.name" required="">
                                    <label class="form-check-label" :for="'for-'+permission.id">{{permission.name}}</label>
                                </div>
                            </div>
                        <hr/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- card -->
        </div>
        <!-- end Col-9 -->
    </div>

    <CreateComponent :CREATE_ROLE = CREATE_ROLE
                     :CREATE_SECTION = CREATE_SECTION
                     :CREATE_PERMISSION_GROUP = CREATE_PERMISSION_GROUP
                     :ROLE_USERS = ROLE_USERS
                     :settings = settings />

    <CreatePermission :ROLE_USERS = ROLE_USERS
                      :settings = settings
                      :sections = sections
                      :groups = groups />
</template>

<script>
import axios from "axios";

const ROLE_USERS = 'ROLE_USERS'
const CREATE = 'CREATE'
const CREATE_ROLE = 'СОЗДАТЬ РОЛЬ'
const CREATE_SECTION = 'СОЗДАТЬ РАЗДЕЛ'
const CREATE_PERMISSION_GROUP = 'СОЗДАТЬ ГРУППУ РАЗРЕШЕНИЙ'
const CREATE_PERMISSION = 'CREATE_PERMISSION'
const UPDATE_ROLE_USERS = 'UPDATE_ROLE_USERS'

import CreateComponent from "./ui/CreateComponent.vue";

import CreatePermission from './ui/CreatePermissionComponent.vue'
export default {
    name: "RolesAndPermissionsComponent",
    components:{CreateComponent,CreatePermission},
    data:()=>({
        ROLE_USERS:ROLE_USERS,
        CREATE:CREATE,
        CREATE_ROLE:CREATE_ROLE,
        CREATE_SECTION:CREATE_SECTION,
        CREATE_PERMISSION_GROUP:CREATE_PERMISSION_GROUP,
        CREATE_PERMISSION:CREATE_PERMISSION,

        UPDATE_ROLE_USERS:UPDATE_ROLE_USERS,
        roles:[],
        count: null,
        visible_form:ROLE_USERS,
        role:null,

        sections:[],
        groups:[],

        permissions:[],
        checkbox_permission:[],
    }),
    mounted() {
        axios.get('/administration/settings/search-roles-and-permissions')
            .then(response => {
                this.roles = response.data
            }).catch(error => {
            console.log(error);
        }).finally(() => this.preloaderClose())
    },
    methods:{
        settings(val,role=null){
            this.visible_form = val
            this.role = role
            if(val === CREATE_PERMISSION || val === UPDATE_ROLE_USERS){
                axios.post('/administration/settings/information').then((response)=> {
                    this.sections = response.data['sections']
                    this.groups = response.data['groups']
                    this.permissions = response.data['permissions']

                    if(val === UPDATE_ROLE_USERS)
                        this.getPermissionRole()

                }).catch(error => {
                    console.log(error)
                });
            }
        },

        getPermissionRole(){
            axios.post('/administration/settings/get-permission-role',{role:this.role})
                .then(response => {
                    this.checkbox_permission = response.data['checkbox_permission']
                }).catch(error => {
                console.log(error);
            })
        },

        deleteRole(rol){
            axios.post('/administration/settings/delete-role',rol).then((response)=> {
                this.roles = response.data
            }).catch(error => {
                console.log(error)
            });
        },

        checkAssignPermission(){
            axios.post('/administration/settings/assign-permission-role',{checkbox_permission:this.checkbox_permission,role:this.role})
                .then(response => {
                    this.checkbox_permission = response.data
                }).catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style scoped>

</style>
