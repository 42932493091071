<template>
    <div class="modal fade create-component" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Настройки</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">

                    <div class="mb-3">
                        <label class="form-label">Выберите действие:</label>
                        <select v-model="form.select" class="form-control form-control-modern">
                            <option :value="CREATE_ROLE">{{CREATE_ROLE}}</option>
                            <option :value="CREATE_SECTION">{{CREATE_SECTION}}</option>
                            <option :value="CREATE_PERMISSION_GROUP">{{CREATE_PERMISSION_GROUP}}</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Название:</label>
                        <input v-model="form.name" type="text" class="form-control form-control-modern" name="productName" required />
                    </div>

                </div>
                <div class="modal-footer">
                    <button @click="settings(ROLE_USERS)" type="button" class="btn btn-light waves-effect" data-bs-dismiss="modal">Отмена</button>
                    <button @click="saveForm" type="button" class="btn btn-primary waves-effect waves-light" data-bs-dismiss="modal">Сохранить</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>

</template>

<script>
import axios from "axios";

export default {
    name: "CreateComponent",
    props:['CREATE_ROLE','CREATE_SECTION','CREATE_PERMISSION_GROUP','ROLE_USERS','settings'],
    data:()=>({
        form:{
            select:null,
            name:null,
        }
    }),
    methods:{
        saveForm(){
            var url = (this.form.select === this.CREATE_ROLE) ? '/administration/settings/create-role'
                : (this.form.select === this.CREATE_SECTION) ? '/administration/settings/create-section'
                : (this.form.select === this.CREATE_PERMISSION_GROUP) ? '/administration/settings/create-permission-group' : '/';
            axios.post(url,this.form).then(response => {
                Object.assign(this.$data.form, this.$options.data().form)
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>

</style>
