<template>
    <div class="ps-1 float-end">
        <select @change="search" v-model="formFilter.filter_category" class="form-select shadow-none form-select-sm">
            <option value="Sales">SALES</option>
            <option value="Orders">ORDERS</option>
            <option value="OrdersBalance">ORDERS BALANCE</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "FilterCategoryComponent",
    props:['search','formFilter']
}
</script>

<style scoped>

</style>
