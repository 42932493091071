<template>

</template>

<script>
export default {
    name: "Scroll",
    methods: {
        scrollTop(val){
            $(val).animate({ scrollTop: 0 }, 1);
        }
    },
}
</script>

<style scoped>

</style>
