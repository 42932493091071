<template>
    <div class="modal fade add-month-fund" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h6 class="modal-title">Распределение прибыли и фондов</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">
                    <div class="card">
                        <div class="card-body">
                            <div class="border border-secondary p-4 text-white" style="border-radius: 10px;background: rgb(56, 63, 79)">
                                <h4 class="card-title text-center py-3">РАСПРЕДЕЛЕНИЕ ПРИБЫЛИ И ФОНДОВ</h4>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-2 col-xl-2 pb-2">
                                            <label class="form-label lh-1">Год </label>
                                            <select @change="selectYearForm" v-model="form.year" class="form-select">
                                                <option v-for="year in list_year" :value="year">{{year}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 pb-2">
                                            <label class="form-label lh-1">Полугодие</label>
                                            <select v-model="form.half" class="form-select">
                                                <option v-for="half in [{name:'1 пг', value:5}, {name:'2 пг', value:11}]"
                                                        :value="half.value">{{half.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 pb-2">
                                            <label class="form-label lh-1">Месяц</label>
                                            <select v-model="form.month" class="form-select">
                                                <option v-for="(month, index) in list_month" :value="index">{{month.ru}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <hr>

                                <div v-if="form.year >= 2024" class="row pt-1">
                                    <div class="col-12 col-lg-2">
                                        <div class="row p-1">
                                            <h6 class="row ps-3 text-success">ПОСТУПИЛО:</h6>
                                            <div v-if="visibilityFund(form.net_profit.name)" class="col-lg-12 pb-2 px-1">
                                                <label class="form-label lh-1 text-info">{{form.net_profit.name}}</label>
                                                <input v-model="form.net_profit.value" class="form-control" type="text"
                                                       @input="event => form.net_profit.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.sinking_fund_start.name)" class="col-lg-12 pb-2 px-1">
                                                <label class="form-label lh-1 text-info">{{form.sinking_fund_start.name}}</label>
                                                <input v-model="form.sinking_fund_start.value" class="form-control" type="text"
                                                       @input="event => form.sinking_fund_start.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-10 px-3">

                                        <div class="row p-1">
                                            <h6 class="row ps-3 text-danger">ПОТРАЧЕНО:</h6>

                                            <div v-if="visibilityFund(form.sinking_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.sinking_fund.name}}</label>
                                                <input v-model="form.sinking_fund.value" class="form-control" type="text"
                                                       @input="event => form.sinking_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.social_development_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.social_development_fund.name}}</label>
                                                <input v-model="form.social_development_fund.value" class="form-control" type="text"
                                                       @input="event => form.social_development_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.social_projects_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1 text-muted">{{form.social_projects_fund.name}}</label>
                                                <input v-model="form.social_projects_fund.value" class="form-control" type="text"
                                                       @input="event => form.social_projects_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.director_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1 text-muted">{{form.director_fund.name}}</label>
                                                <input v-model="form.director_fund.value" class="form-control" type="text"
                                                       @input="event => form.director_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.strategic_development_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.strategic_development_fund.name}}</label>
                                                <input v-model="form.strategic_development_fund.value" class="form-control" type="text"
                                                       @input="event => form.strategic_development_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.viliya_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.viliya_fund.name}}</label>
                                                <input v-model="form.viliya_fund.value" class="form-control" type="text"
                                                       @input="event => form.viliya_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.reserve_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.reserve_fund.name}}</label>
                                                <input v-model="form.reserve_fund.value" class="form-control" type="text"
                                                       @input="event => form.reserve_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.dividend_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.dividend_fund.name}}</label>
                                                <input v-model="form.dividend_fund.value" class="form-control" type="text"
                                                       @input="event => form.dividend_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                            <div v-if="visibilityFund(form.year_fund.name)" class="col-lg-3 pb-2 px-1">
                                                <label class="form-label lh-1">{{form.year_fund.name}}</label>
                                                <input v-model="form.year_fund.value" class="form-control" type="text"
                                                       @input="event => form.year_fund.value = event.target.value.replace(/[^\d,.-]/g,'').replace(',','.')">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center py-2">
                                        <button @click="clearForm" type="button" class="btn btn-danger waves-effect px-4">Очистить</button>
                                        <button @click="saveFundMonth" type="button" class="ms-2 btn btn-primary px-4">Сохранить</button>
                                    </div>
                                </div>

                            </div>

                            <h4 class="card-title text-center pt-5 pb-3">ОТЧЁТ О РАСПРЕДЕЛЕНИИ ПРИБЫЛИ И ФОНДОВ</h4>

                            <Fund2023 v-if="form.year === 2023" :form="form" :funds="funds" :list_month="list_month" :total="total" />
                            <Fund2024 v-if="form.year === 2024" :form="form" :funds="funds" :list_month="list_month" :total="total" />
                        </div>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>

</template>

<script>
import axios from "axios";
import Fund2023 from "./fund/Fund2023.vue"
import Fund2024 from "./fund/Fund2024.vue"

export default {
    name: "AddModalFundsComponent",
    props:['filter_mtm', 'funds', 'total', 'list_month', 'search_fund'],
    components:{Fund2023,Fund2024},
    data:()=>({
        form: {
            year: new Date().getFullYear(),
            month: null,
            half:11,
            net_profit:{
                id: 1,
                name: 'Чистая прибыль',
                value: null
            },
            sinking_fund_start: {
                id: 2,
                name:'Амортизационный фонд',
                value: null
            },
            sinking_fund:{
                id: 3,
                name: 'Амортизационный фонд',
                value: null,
            },
            social_development_fund:{
                id: 4,
                name: 'Фонд социального развития',
                value:null
            },
            social_projects_fund:{
                id: 5,
                name: 'в т.ч. фонд соцпроектов',
                value: null
            },
            director_fund:{
                id: 6,
                name:'фонд директора',
                value:null
            },
            strategic_development_fund:{
                id: 7,
                name: 'Фонд стратегического развития',
                value: null
            },
            viliya_fund:{
                id: 8,
                name: 'Фонд Вилия',
                value:null
            },
            reserve_fund:{
                id: 9,
                name: 'Резервный фонд',
                value: null
            },
            dividend_fund:{
                id: 10,
                name:'Фонд дивидендов',
                value:null,
            },
            year_fund:{
                id: 11,
                name:'Фонд годового бонуса',
                value:null,
            }
        },
        list_year: [new Date().getFullYear() - 1, new Date().getFullYear()],
    }),
    mounted() {
        this.form.year = this.filter_mtm.select_year
    },
    watch: {
        'filter_mtm.select_year': function (val, oldVal) {
            this.form.year = this.filter_mtm.select_year
        },
        // 'form.year': function (val,oldVal){
        //
        // },
        'form.month': function (val, oldVal){
            if(val || val === 0){
                for (let fund of this.funds) {
                    if(fund.month_to === this.form.half){
                        if(fund.id_found === 1) this.form.net_profit.value = fund[this.list_month[val].en]
                        if(fund.id_found === 2) this.form.sinking_fund_start.value = fund[this.list_month[val].en]
                        if(fund.id_found === 3) this.form.sinking_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 4) this.form.social_development_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 5) this.form.social_projects_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 6) this.form.director_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 7) this.form.strategic_development_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 8) this.form.viliya_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 9) this.form.reserve_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 10) this.form.dividend_fund.value = fund[this.list_month[val].en]
                        if(fund.id_found === 11) this.form.year_fund.value = fund[this.list_month[val].en]
                    }
                }
            }
        },
        'form.half': function (val, oldVal){
            this.form.month = null
        }
    },
    methods:{
        visibilityFund(elem){
          return this.funds.find((i) => i.name === elem);
        },
        saveFundMonth(){
            if(this.form.month || this.form.month === 0) {
                this.preloaderShow()
                axios.post('/analytics/funds/save-funds-month',this.form).then(response => {
                    this.clearForm()
                    this.$emit('search_fund')
                }).finally(() => {
                    this.preloaderClose()
                })
            }
        },
        selectYearForm(){
            this.filter_mtm.select_year = this.form.year
            this.$emit('search_fund')
            this.clearForm()
        },
        clearForm(){
            Object.assign(this.$data.form, this.$options.data().form)
            this.form.year = this.filter_mtm.select_year
        },
    },

}
</script>

<style scoped>

</style>
