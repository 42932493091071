<template>

</template>

<script>
import moment from "moment";

export default {
    name: "FormatDate",
    methods:{
        araar(date){
            return moment().isBefore(date)
        },
        formatDateDMY(data){
            return moment(data).format('DD.MM.YYYY')
        },
        formatDateDMYHM(data){
            return moment(data).format('DD.MM.YYYY HH:mm')
        },
        formatDateDMYHMS(data){
            return moment(data).format('DD.MM.YYYY HH:mm:ss')
        },

        formatDateHMS(data){
            return moment(data).format('HH:mm:ss')
        },

        formatDateDM(data){
            return moment(data).format('DD.MM')
        },
        formatDateD(data){
            return moment(data).format('DD')
        },
        formatDateM(data){
            return moment(data).format('MM')
        },
        formatDateMMinusOne(data){
            return moment(data).format('MM')-1
        },
        formatDateY(data){
            return moment(data).format('YYYY')
        },

        formatDateDashYMD(data){
            return moment(data).format('YYYY-MM-DD')
        },

        formatNumber(number){
            return new Intl.NumberFormat('ru-RU').format(number)
        },

        period(period){
            switch (period.filter_period){
                case 'D':
                    period.filter_period_start = this.formatDateDashYMD(new Date())
                    period.filter_period_end = this.formatDateDashYMD(new Date()) // учет времени конец дня
                    break
                case 'W':
                    period.filter_period_start = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-7))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'WTD':
                    period.filter_period_start = this.formatDateDashYMD(moment(new Date().setDate(new Date().getDate()-1)).startOf('isoWeek'))//this.formatDateDashYMD(new Date().setDate(new Date().getDate()-8))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'M':
                    period.filter_period_start = this.formatDateDashYMD(new Date().setMonth(new Date().getMonth()-1)) //.formatDateDashYMD(new Date().setDate(1))//
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'MTD':
                    period.filter_period_start = this.formatDateDashYMD( moment(new Date().setDate(new Date().getDate()-1)).startOf('month'))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case '3M':
                    period.filter_period_start = this.formatDateDashYMD(new Date().setMonth(new Date().getMonth()-3))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'QTD':
                    period.filter_period_start = this.formatDateDashYMD(moment(new Date().setDate(new Date().getDate()-1)).startOf('quarter'))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'Y':
                    period.filter_period_start = this.formatDateDashYMD(new Date().setFullYear(new Date().getFullYear()-1))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                case 'YTD':
                    period.filter_period_start = this.formatDateDashYMD(moment(new Date().setDate(new Date().getDate()-1)).startOf('year'))
                    period.filter_period_end = this.formatDateDashYMD(new Date().setDate(new Date().getDate()-1))
                    break
                // case 'MTM':
                    // period.filter_period_start = this.formatDateDashYMD()
                    // period.filter_period_end = this.formatDateDashYMD()
                    // break
                // case 'DTD':
                    // period.filter_period_start = this.formatDateDashYMD()
                    // period.filter_period_end = this.formatDateDashYMD()
                    // break
            }
        },


    }
}
</script>

<style scoped>

</style>
