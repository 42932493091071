<template>
    <!-- start: page -->
    <div class="row">
        <div class="col-lg-4 col-xl-3 mb-4 mb-xl-0">

            <section class="card">
                <div class="card-body">
                    <div class="thumb-info mb-3">
                        <img :src="l_user" class="rounded img-fluid" :alt="profile">
                        <div class="thumb-info-title">
                            <span class="thumb-info-inner">{{profile}}</span>
                            <span class="thumb-info-type">{{ email }}</span>
                        </div>
                    </div>

                    <div class="widget-toggle-expand mb-0">
                        <div class="widget-header">
                            <h5 class="mb-0 font-weight-semibold text-dark">{{user_profile.Company}}</h5>
<!--                            <div class="widget-toggle">+</div>-->
                        </div>
                    </div>

                        <h5 class="mb-2 mt-0">г. Заславль ул. Якуба Коласа 2б</h5>
<!--                    <hr class="dotted short">-->
                </div>
            </section>
        </div>


        <div class="col-lg-8 col-xl-6">

            <div class="tabs">
                <ul class="nav nav-tabs tabs-primary">
                    <li class="nav-item active">
                        <button class="nav-link" data-bs-target="#edit" data-bs-toggle="tab">КОНТАКТНАЯ ИНФОРМАЦИЯ</button>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="edit" class="tab-pane active">

                        <div class="p-3">
                            <h4 class="mb-3 font-weight-semibold text-dark">ПОЛЬЗОВАТЕЛЬ</h4>
                            <div class="row mb-4">
                                <div class="form-group col">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <label for="profile_F">Фамилия</label>
                                            <input type="text" :value="user_profile.Surname" id="profile_F" class="form-control" readonly="readonly">
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <label for="profile_I">Имя</label>
                                            <input type="text" :value="user_profile.Name" id="profile_I" class="form-control" readonly="readonly">
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <label for="profile_O">Отчество</label>
                                            <input type="text" :value="user_profile.SecondName" id="profile_O" class="form-control" readonly="readonly">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="form-group col">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <label for="profile_d_b">Дата рождения</label>
                                            <input type="text" :value="user_profile.DateOfBirth" id="profile_d_b" class="form-control" readonly="readonly">
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <label for="profile_e">Email</label>
                                            <input type="text" :value="user_profile.Email" id="profile_e" class="form-control" readonly="readonly">
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <label for="profile_t">Телефон</label>
                                            <input type="text" :value="user_profile.Phone" id="profile_t" class="form-control" readonly="readonly">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="form-group col">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <label for="profile_division">Отдел / Подразделение</label>
                                            <input type="text" :value="user_profile.Division" id="profile_division" class="form-control" readonly="readonly">
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <label for="profile_brigade">Смена / Бригада</label>
                                            <input type="text" :value="user_profile.Brigade" id="profile_brigade" class="form-control" readonly="readonly">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <label for="profile_position">Должность</label>
                                    <input type="text" :value="user_profile.Position" id="profile_position" class="form-control" readonly="readonly">
                                </div>
                            </div>

                            <hr class="dotted tall">

                            <h4 class="mb-3 font-weight-semibold text-dark">СМЕНА ПАРОЛЯ</h4>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Новый пароль</label>
                                    <input type="password" class="form-control" id="inputPassword4" placeholder="Password">
                                </div>
                                <div class="form-group col-md-6 border-top-0 pt-0">
                                    <label for="inputPassword5">Повторите новый пароль</label>
                                    <input type="password" class="form-control" id="inputPassword5" placeholder="Password">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-end mt-3">
                                    <button class="btn btn-default modal-confirm">Сохранить</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>



        <div class="col-xl-3">
            <h4 class="mb-3 mt-0 font-weight-semibold text-dark">ДОПОЛНИТЕЛЬНО</h4>
            <ul class="simple-card-list mb-3">
                <a href="/work/time-tracking"><li class="primary">
                    <h3 class="fs-6">УЧЕТ РАБОЧЕГО ВРЕМЕНИ</h3>
                    <p class="text-light" style="line-height: 18px">Ведение учета рабочего времени</p>
                </li></a>
                <a href="/work/schedule"><li class="warning">
                    <h3 class="fs-6">ГРАФИК РАБОТЫ</h3>
                    <p class="text-light" style="line-height: 18px">Информация о рабочих днях на текущий год</p>
                </li></a>
                <a href="/work/part-time-schedule"><li class="success">
                    <h3 class="fs-6">ПОДРАБОТКА</h3>
                    <p class="text-light" style="line-height: 18px">Информация о возможной подработке</p>
                </li></a>
            </ul>

            <h4 class="mb-3 mt-4 pt-2 font-weight-semibold text-dark">ПОДЧИНЕННЫЕ</h4>
            <div class="smooth-scroll overflow-auto" style="height: 450px">
            <ul class="simple-user-list mb-3">
                <li v-for="subordinate in subordinates" v-show="subordinate.ID !== user_profile.ID">
                    <figure class="image rounded">
                        <img :src="sample_user" alt="Joseph Doe Junior" class="rounded-circle">
                    </figure>
                    <span class="title">{{subordinate.Surname +' '+ subordinate.Name +' '+ subordinate.SecondName}}</span>
                    <span class="message">{{subordinate.Position}}</span>
                </li>
            </ul>
            </div>
        </div>
    </div>
    <!-- end: page -->
</template>

<script>
import axios from "axios";

export default {
    name: "ProfileComponent",
    props:['profile','email'],
    data:()=>({
        l_user:'/app/img/luser.jpg',
        sample_user:'/app/img/sampleuser.jpg',
        user_profile:[],
        subordinates:[],
    }),
    mounted() {
        this.searchUserProfile()
    },
    methods:{
      searchUserProfile(){
          axios.get('/search-user-profile')
              .then(response => {
                  this.user_profile = response.data['user']['data'][0]
                  this.subordinates = response.data['subordinates']['data']
              }).catch(error => {
              console.log(error);
          })//.finally(() => (this.loading = false));
      },
    }
}
</script>

<style scoped>

</style>
