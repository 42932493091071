<template>
    <div class="row">
        <div class="col-12">
            <div class="d-sm-flex align-items-center justify-content-between">
                <h6 class="text-muted">Информация на текущий день.</h6>
                <div class="page-title-right">
                    <form class="app-search">
                        <div class="position-relative bg-light" style="border-radius: 10px">
                            <input v-model="input_search" type="text" class="form-control" placeholder="Поиск...">
                            <span class="ri-search-line"></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    <div class="col-xxl-12 col-xl-12">
        <div class="card">
            <div class="card-body border">
<!--                <h4 class="card-title mb-3">Подробно по регионам-->
<!--                    <div><small class="text-muted">Coswick Solid Hardwood Floors.</small></div>-->
<!--                </h4>-->

            <div class="table-responsive">
                <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                    <thead class="table-light">
                    <tr>
<!--                        <th>Компания</th>-->
                        <th>Компания / Контрагент</th>
                        <th>Дебиторка BYN</th>
                        <th>Кредиторка BYN</th>
                        <th>Дебиторка EUR</th>
                        <th>Кредиторка EUR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="text-primary" v-if="!!list_statement_kontragent.length && !input_search">
                        <td class="fw-bold">ПО ВСЕМ КОНТРАГЕНТАМ</td>
                        <td>Br {{formatNumber(list_statement_kontragent_all.SummaDTBYN)}}</td>
                        <td>Br {{formatNumber(list_statement_kontragent_all.SummaKTBYN)}}</td>
                        <td>€ {{formatNumber(list_statement_kontragent_all.SummaDTEUR)}}</td>
                        <td>€ {{formatNumber(list_statement_kontragent_all.SummaKTEUR)}}</td>
                    </tr>
                    <tr v-for="list in list_statement">
<!--                        <td class="text-wrap"><h6 class="mb-0">{{list.Company}}</h6></td>-->
                        <td class="text-wrap"><div class="mb-0 text-muted">{{list.Company}}</div><h6 class="mb-0">{{list.Kontragent}}</h6></td>
                        <td><h6 class="mb-0" v-bind:class="list.SummaDTBYN < 0 ? 'text-warning' : list.SummaDTBYN > 0 ? 'text-info' : 'text-muted'">Br {{formatNumber(list.SummaDTBYN)}}</h6></td>
                        <td><h6 class="mb-0" v-bind:class="list.SummaKTBYN < 0 ? 'text-warning' : list.SummaKTBYN > 0 ? 'text-info' : 'text-muted'">Br {{formatNumber(list.SummaKTBYN)}}</h6></td>
                        <td><h6 class="mb-0" v-bind:class="list.SummaDTEUR < 0 ? 'text-warning' : list.SummaDTEUR > 0 ? 'text-info' : 'text-muted'">€ {{formatNumber(list.SummaDTEUR)}}</h6></td>
                        <td><h6 class="mb-0" v-bind:class="list.SummaKTEUR < 0 ? 'text-warning' : list.SummaKTEUR > 0 ? 'text-info' : 'text-muted'">€ {{formatNumber(list.SummaKTEUR)}}</h6></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </div>

        </div>
    </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "StatementComponent",
    data:()=>({
        list_statement_kontragent:[],
        list_statement_kontragent_all:[],
        input_search:''
    }),
    mounted() {
        this.search()
    },
    computed: {
        list_statement(){
            return this.input_search ? this.list_statement_kontragent.filter(res => res.Kontragent.toLowerCase().includes(this.input_search.toLowerCase())) : this.list_statement_kontragent
        }
    },
    methods: {
        search() {
            axios.post('/analytics/funds/statement/search').then(response => {
                this.list_statement_kontragent = response.data['data'].filter(res => res.Kontragent !== '' && res.Valuta === '').sort((a,b) => a.Kontragent.localeCompare(b.Kontragent))
                this.list_statement_kontragent_all = response.data['data'].filter(res => res.Company === '' && res.Kontragent === '')[0]
            }).catch(error => {
            }).finally(() => {
                this.preloaderClose()
            });
        },
    }
}
</script>

<style scoped>

</style>
