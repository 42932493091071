<template>

</template>

<script>
export default {
    name: "Preloader",
    methods:{
        preloaderClose(){
            $("#status").fadeOut(1)
            $("#preloader").fadeOut(1)
        },
        preloaderShow(){
            $("#status").fadeIn(1)
            $("#preloader").fadeIn(1)
        },
    }
}
</script>

<style scoped>

</style>
