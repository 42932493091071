<template>
    <div v-if="filter_dtd.dtd" class="ps-1" style="max-width: 58px">
        <v-date-picker v-model="filter_dtd.date_start" color="red">
            <template v-slot="{ inputValue, inputEvents }"  >
                <input
                    class="form-select shadow-none form-select-sm"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="с" readonly />
            </template>
        </v-date-picker>
    </div>
    <div v-if="filter_dtd.dtd" class="ps-1" style="max-width: 58px">
        <v-date-picker v-model="filter_dtd.date_end" @change="selectDTD" color="red">
            <template v-slot="{ inputValue, inputEvents }"  >
                <input
                    class="form-select shadow-none form-select-sm"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="по" readonly />
            </template>
        </v-date-picker>
    </div>

</template>

<script>
export default {
    name: "FilterDTDComponent",
    props:['search','filter_dtd','formFilter'],
    computed:{
        selectDTD(){
            if(this.filter_dtd.date_start && this.filter_dtd.date_end){
                this.formFilter.filter_period_start = this.formatDateDashYMD(this.filter_dtd.date_start)
                this.formFilter.filter_period_end = this.formatDateDashYMD(this.filter_dtd.date_end)

                this.search()
            }
        }
    },
}
</script>

<style scoped>

</style>
