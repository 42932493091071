<template>

</template>

<script>
export default {
    name: "RegisterComponent",
    props:['register'],
    created() {
        this.preloaderClose()
    },
}
</script>

<style scoped>

</style>
