<template>
    <div class="ps-1 float-end">
        <select v-model="formFilter.filter_period" :onchange="selectPeriod" class="form-select shadow-none form-select-sm">
            <option value="D">D</option>
            <option value="W">W</option>
            <option value="WTD">WTD</option>
            <option value="M">M</option>
            <option value="MTD">MTD</option>
            <option value="3M">3M</option>
            <option value="QTD">QTD</option>
            <option value="Y">Y</option>
            <option value="YTD">YTD</option>
            <option value="MTM">MTM</option>
            <option value="DTD">DTD</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "FilterPeriodComponent",
    props:['formFilter','selectPeriod']
}
</script>

<style scoped>

</style>
