<template>
<!--    <h5 class="mt-0 mb-0">Coswick. Inspire</h5>-->
<!--    <p class="mb-4">Coswick Solid Hardwood Floors.</p>-->
    <div class="row mb-5">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="text-center mt-2">
                    <h5>СИСТЕМА УЧЕТА ПОКАЗАТЕЛЕЙ</h5>
                    <p class="text-muted mb-3">Совокупность количественных или качественных индикаторов, отражающих состояние отдельных сторон деятельности предприятия (бизнес или другого объекта управления), используемая с целью принятия управленческих решений.</p>
                </div>
            </div>
        </div>
        <Btn v-if="$can('2. ДОСТУП К РАЗДЕЛУ ПРОДАЖИ')"
             :name="'ПРОДАЖИ'"
             :role="$can('2. ДОСТУП К РАЗДЕЛУ ПРОДАЖИ')"
             :img="'/assets/images/small/img-12.jpg'"
             :click="$can('2. ДОСТУП К РАЗДЕЛУ ПРОДАЖИ') ? 'location.href=`/analytics/sales`' : ''" />
        <div v-else class="col-12 col-sm-4 py-1 px-1">
            <div class="border text-center rounded p-4" style="height: 204px"></div>
        </div>

        <Btn v-if="$can('3. ДОСТУП К РАЗДЕЛУ УПРАВЛЕНИЕ ЦЕНАМИ')"
             :name="'УПРАВЛЕНИЕ ЦЕНАМИ'"
             :role="$can('3. ДОСТУП К РАЗДЕЛУ УПРАВЛЕНИЕ ЦЕНАМИ')"
             :img="'/assets/images/small/img-11.jpg'"
             :click="$can('3. ДОСТУП К РАЗДЕЛУ УПРАВЛЕНИЕ ЦЕНАМИ') ? 'location.href=`/analytics/price-management`' : ''" />
        <div v-else class="col-12 col-sm-4 py-1">
            <div class="border p-3 text-center rounded" style="height: 204px"></div>
        </div>

        <Btn v-if="$can('9. ДОСТУП К РАЗДЕЛУ ЗАПАСЫ ГОТОВОЙ ПРОДУКЦИИ')"
             :name="'ЗАПАСЫ ГОТОВОЙ ПРОДУКЦИИ'"
             :role="$can('9. ДОСТУП К РАЗДЕЛУ ЗАПАСЫ ГОТОВОЙ ПРОДУКЦИИ')"
             :img="'/assets/images/small/img-14.jpg'"
             :click="$can('9. ДОСТУП К РАЗДЕЛУ ЗАПАСЫ ГОТОВОЙ ПРОДУКЦИИ') ? 'location.href=`/analytics/finished-goods-warehouse`' : ''" />
        <div v-else class="col-12 col-sm-4 py-1">
            <div class="border p-3 text-center rounded" style="height: 204px"></div>
        </div>

        <Btn v-if="$can('7. ДОСТУП К РАЗДЕЛУ VILIYA PARK')"
             :name="'VILIYA PARK'"
             :role="$can('7. ДОСТУП К РАЗДЕЛУ VILIYA PARK')"
             :img="'/assets/images/small/img-13.jpg'"
             :click="$can('7. ДОСТУП К РАЗДЕЛУ VILIYA PARK') ? 'location.href=`/analytics/viliya-park`' : ''" />
        <div v-else class="col-12 col-sm-4 py-1">
            <div class="border p-3 text-center rounded" style="height: 204px"></div>
        </div>

        <Btn v-if="$can('8. ДОСТУП К РАЗДЕЛУ KPI СОТРУДНИКОВ')"
             :name="'МОТИВАЦИЯ ПЕРСОНАЛА'"
             :role="$can('8. ДОСТУП К РАЗДЕЛУ KPI СОТРУДНИКОВ')"
             :img="'/assets/images/small/img-15.jpg'"
             :click="$can('8. ДОСТУП К РАЗДЕЛУ KPI СОТРУДНИКОВ') ? 'location.href=`/analytics/kpi`' : ''" />
        <div v-else class="col-12 col-sm-4 py-1">
            <div class="border p-3 text-center rounded" style="height: 204px"></div>
        </div>
    </div>
</template>

<script>
import Btn from "./BtnComponent.vue";
export default {
    name: "HomeComponent",
    props:['username','useremail'],
    components:{Btn},
    created() {
        this.preloaderClose()
    },
    methods:{
    }
}
</script>

<style scoped>
</style>
