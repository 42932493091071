<template>
    <div class="row" v-for="warehouse in data_warehouse.sort((a,b) => strToDate(b)-strToDate(a))"><!--.slice(-1)-->
        <h6>{{warehouse}}</h6>
        <div v-if="!!list_warehouse.length" v-for="warehouse in list_warehouse.filter(x => x.PeriodOstatkov === warehouse)"
             class="col-xxl-3 col-xxl-mac-3 col-xl-6 col-md-6">
            <div class="card mb-md-4 mb-3 " v-bind:class="warehouse.Region || 'total'">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1">
                                <h6 class="font-size-11 mb-0 text-muted text-lowercase">СКЛАД</h6>
                                <h6 class="text-uppercase mb-1 text-primary"><span class="text-secondary"></span> {{warehouse.Region}}</h6>
<!--                                <div class="lh-1"><small class="text-muted">{{specificGravity(os.KolichestvoFact)}}% - удельный вес</small></div>-->
                            </div>
                            <div class="d-flex">
                                <h5 class="mb-0 text-success">€ {{formatNumber(warehouse.StoimostFact.toFixed(2))}}</h5>
                                <h5 class="mb-0 ms-auto">{{formatNumber(warehouse.Quantity)}} м²</h5>
                            </div>

                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div>

    <div class="row">
        <div v-if="!!list_warehouse.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card">
                <div class="card-body border">
                    <h4 class="card-title mb-3">Подробно
                        <div><small class="text-muted">Coswick Solid Hardwood Floors.</small></div>
                    </h4>

                    <div class="table-responsive">
                        <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                            <thead class="table-light">
                            <tr>
                                <th>Дата</th>
                                <th>Склад </th>
                                <th>Стоимость, €</th>
                                <th>Кол-во</th>
                            </tr>
                            </thead><!-- end thead -->
                            <tbody v-for="warehouse in data_warehouse.sort((a,b) => strToDate(b)-strToDate(a))">
                            <tr class="mb-0 text-warning"><td colspan="4">{{warehouse}}</td></tr>
                            <tr v-for="warehouse in list_warehouse.filter(x => x.PeriodOstatkov === warehouse)">
                                <td>{{warehouse.PeriodOstatkov}}</td>
                                <td class="text-info">{{warehouse.Region}}</td>
                                <td class="text-success">€ {{formatNumber(warehouse.StoimostFact)}}</td>
                                <td class="text-dark">{{formatNumber(warehouse.Quantity)}} м²</td>
                            </tr>
                            </tbody><!-- end tbody -->
                        </table> <!-- end table -->
                    </div>
                </div><!-- end card -->
            </div><!-- end card -->
        </div>

        <div v-if="!!series[0].data.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card">
                <div class="card-body border">
                    <h4 class="card-title mb-0">Диаграмма по складам
                        <div><small class="text-muted">Coswick Solid Hardwood Floors.</small></div>
                    </h4>
                    <div v-if="!!series[0].data.length && chartOptions">
                        <h6 class="mt-4 mb-0 fw-bold">Запасы готовой продукции, млн. €</h6>
                        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "FinishedGoodsWarehouseComponent",
    data:()=>({
        list_warehouse:[],
        data_warehouse:[],
        warehouse_region:[],

        series: [{name:' , м²', data: []}],
        chartOptions: {},

        windowWidth: window.innerWidth,
    }),
    mounted() {
        this.search()
        // this.$nextTick(() => {
        //     window.addEventListener('resize', this.onResize);
        // })
    },
    // beforeDestroy() {
    //     window.removeEventListener('resize', this.onResize);
    // },
    methods: {
        // onResize() {
        //     this.windowWidth = window.innerWidth
        // },

        search() {
            axios.post('/analytics/search-finished-goods-warehouse').then(response => {
                this.list_warehouse = response.data['data']
                this.data_warehouse = this.list_warehouse.filter((lists, index, self) =>
                        self.findIndex(t => t.PeriodOstatkov === lists.PeriodOstatkov && lists.PeriodOstatkov) === index)
                        .map(x => x.PeriodOstatkov)

                this.warehouse_region = this.list_warehouse.filter((lists, index, self) =>
                    self.findIndex(t => t.Region === lists.Region && lists.Region) === index)
                    .map(x => x.Region)

                for(let i = 0; i < this.warehouse_region.length; i++){
                    this.series[i] = {
                        name: this.warehouse_region[i],
                        group: 'budget',
                        data: this.list_warehouse.filter(x => x.Region === this.warehouse_region[i])
                            .map(x => (Math.trunc((x.StoimostFact / 1000000) * 100) / 100).toFixed(3))
                    }
                }

                this.chartOptions = {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    dataLabels: {
                        formatter: (val) => {
                            return Math.trunc(val * 100) / 100  + ' млн. €'
                        },
                    },

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: this.windowWidth < 500 ? 50 : this.windowWidth < 750 ? 85 : (this.windowWidth >= 1250 && this.windowWidth < 1700) ? 180 : 115,
                                    offsetY: -15,
                                    style: {
                                        fontSize: '12px',
                                        color:'#fff',
                                    },
                                },
                            }

                        }
                    },
                    xaxis: {
                        categories: this.list_warehouse.filter((lists, index, self) =>
                            self.findIndex(t => t.PeriodOstatkov === lists.PeriodOstatkov && lists.PeriodOstatkov) === index)
                            .sort((a,b) => this.strToDate(a.PeriodOstatkov)-this.strToDate(b.PeriodOstatkov))
                            .map(x => x.PeriodOstatkov)
                    },
                    fill: {
                        opacity: 1
                    },
                    colors: ['#ffbfaa', '#00E396', '#ff5232'],
                    yaxis: {
                        labels: {
                            formatter: (val) => {
                                return Math.trunc(val * 100) / 100
                            }
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                    },
                }


            }).finally(() => {
                this.preloaderClose()
            })
        },
        strToDate(val){
            let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            return new Date(val.replace(pattern,'$3-$2-$1'));
        }
    }
}
</script>

<style scoped>

</style>
