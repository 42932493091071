<template>
    <div v-show="filter_mtm.select_year" class="ps-1 float-end">
        <select v-model="filter_mtm.select_year" @change="selectMonth" class="form-select shadow-none form-select-sm">
            <option v-for="year in years" :value="year">{{year}}</option>
        </select>
    </div>
    <div v-show="filter_mtm.mtm" class="ps-1 float-end">
        <select v-model="filter_mtm.select_month" @change="selectMonth" class="form-select shadow-none form-select-sm">
            <option v-for="(m,key) in month" :value="key">{{m}}</option>
        </select>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "FilterMTMComponent",
    props:['formFilter','search','filter_mtm'],
    data:()=>({
        month:['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
        years:[moment().subtract(1,'Y').year(), moment().year()]
    }),

    mounted() {
        if(this.filter_mtm.select_month !== null){
            this.selectMonth()
        }
    },

    methods:{
        selectMonth(){
            this.formFilter.filter_period_start = this.filter_mtm.select_year
                ? this.formatDateDashYMD(moment(this.filter_mtm.select_month+1+'.'+this.filter_mtm.select_year,'MM.YYYY').startOf('month'))
                : this.formatDateDashYMD(moment(this.filter_mtm.select_month+1,'MM').startOf('month'))
            this.formFilter.filter_period_end = this.filter_mtm.select_year
                ? this.formatDateDashYMD(moment(this.filter_mtm.select_month+1+'.'+this.filter_mtm.select_year,'MM.YYYY').endOf('month'))
                : this.formatDateDashYMD(moment(this.filter_mtm.select_month+1,'MM').endOf('month'))

            this.search()
        }
    }
}
</script>

<style scoped>

</style>
