<template>
    <div v-if="!!oneFunds.length" class="table-responsive" style="background: rgb(56, 63, 79)">
        <table class="table table-bordered border-secondary table-hover mb-0 font-size-12">
            <thead>
            <tr class="text-white">
                <th rowspan="2">Фонд</th>
                <th class="text-center" rowspan="2">Источник пополнения</th>
                <th colspan="4" class="text-center table-style-1">{{form.year}} / 1 нарастающим итогом</th>
                <th colspan="4" class="text-center table-style-2">{{form.year}} / 2 нарастающим итогом</th>
                <th colspan="2" v-for="list in list_month" class="text-center">{{list.ru}}</th>
            </tr>
            <tr class="text-white">
                <th class="text-center table-style-1">начало</th>
                <th class="text-center table-style-1">+</th>
                <th class="text-center table-style-1">-</th>
                <th class="text-center table-style-1">конец</th>

                <th class="text-center table-style-2">начало</th>
                <th class="text-center table-style-2">+</th>
                <th class="text-center table-style-2">-</th>
                <th class="text-center table-style-2">конец</th>

                <template v-for="list in list_month.length">
                    <th class="text-center text-success">+</th>
                    <th class="text-center text-danger">-</th>
                </template>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr v-for="fund in uniqFunds">

                <th v-if="fund" class="py-2 text-start lh-sm"
                    v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info'
                                    : fund.id_found === 5 ? 'ps-4'
                                    : fund.id_found === 6 ? 'ps-5'
                                    : 'text-white'">{{fund.name}}</th>
                <td v-if="fund" class="py-2">
                    <div v-for="(f,index) in funds.filter(x => x.id_found === fund.id_found)" class="lh-sm">
                        {{ (f.percent || f.percent === 0) && f.id_found !== 11
                                    ? f.percent + '% от ЧП - '+ (++index) +' пг'
                                    : f.id_found === 11 ? 'со 2 пг ' + f.percent + '% от ЧП' : ''}}
                    </div>
                </td>

                <!--1-->
                <td class="py-2 text-white table-style-1">
                    {{filterOneFund(fund.id_found) ? filterOneFund(fund.id_found).start_total : ''}}
                </td>
                <td class="py-2 table-style-1" v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info'
                                    : (fund.id_found === 5 || fund.id_found === 6 ? 'text-muted' : 'text-success')">
                    {{fund.id_found === 1 || fund.id_found === 2
                            ? formatNumber(Math.round(sumFundMonth(filterOneFund(fund.id_found))))
                            : (sumOneFundReceived(fund) && fund.id_found !== 11 ? formatNumber(Math.round(sumOneFundReceived(fund))) : '')}}</td>
                <td class="py-2 table-style-1" :class="fund.id_found === 5 || fund.id_found === 6 ? 'text-muted' : 'text-danger'">
                    {{fund.id_found !== 1 && fund.id_found !== 2 ? (sumFundMonth(filterOneFund(fund.id_found)) ? formatNumber(Math.round(sumFundMonth(filterOneFund(fund.id_found)))) : '') : ''}}
                </td>
                <td class="py-2 text-white table-style-1">
                    {{fund.id_found !== 1 && fund.id_found !== 2 && fund.id_found !== 11
                        ? formatNumber(Math.round(fund.start_total + sumOneFundReceived(fund) - sumFundMonth(filterOneFund(fund.id_found))))
                        : ''}}
                </td>

<!--                <td class="py-2 text-white">{{Math.round(fund.start_total) || ''}}</td>-->

<!--                <td v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info'-->
<!--                                    : (fund.id_found === 5 || fund.id_found === 6 ? 'text-muted' : 'text-success')" class="py-2">-->
<!--                    {{fund.id_found === 1 || fund.id_found === 2 ? formatNumber(Math.round(sumFundMonth(fund)))-->
<!--                            : (sumFundReceived(fund) ? formatNumber(Math.round(sumFundReceived(fund))) : '')}}</td>-->

<!--                <td class="py-2 " :class="fund.id_found === 5 || fund.id_found === 6 ? 'text-muted' : 'text-danger'">{{fund.id_found !== 1 && fund.id_found !== 2 ? (sumFundMonth(fund) ? formatNumber(Math.round(sumFundMonth(fund))) : '') : ''}}</td>-->
<!--                <td class="py-2 text-white">{{fund.id_found !== 1 && fund.id_found !== 2 ? formatNumber(Math.round(fund.start_total + sumFundReceived(fund) - sumFundMonth(fund))) : ''}}</td>-->

                <!--2-->
<!--                <td class="py-2 text-white">{{funds.find(x => x.id_found === fund.id_found && x.month_to === 11).start_total ?? ''}}</td>-->
<!--                <td class="py-2 text-white">{{Math.round(funds.find(x => x.id_found === fund.id_found && x.month_from === 6 && x.month_to === 11).start_total) || ''}}</td>-->

                <td class="py-2 text-white table-style-2">
                    {{filterTwoFund(fund.id_found) ? filterTwoFund(fund.id_found).start_total : ''}}
                </td>
                <td class="py-2 table-style-2" v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info'
                                                    : (fund.id_found === 5 || fund.id_found === 6 ? 'text-muted'
                                                    : 'text-success')">
                    {{fund.id_found === 1 || fund.id_found === 2
                    ? formatNumber(Math.round(sumFundMonth(filterTwoFund(fund.id_found))))
                    : (sumTwoFundReceived(fund) ? formatNumber(Math.round(sumTwoFundReceived(fund))) : '')}}
                </td>
                <td class="py-2 table-style-2" :class="fund.id_found === 5 || fund.id_found === 6 ? 'text-muted' : 'text-danger'">
                    {{fund.id_found !== 1 && fund.id_found !== 2 ? (sumFundMonth(filterTwoFund(fund.id_found)) ? formatNumber(Math.round(sumFundMonth(filterTwoFund(fund.id_found)))) : '') : ''}}
                </td>
                <td class="py-2 text-white table-style-2">
                    {{fund.id_found !== 1 && fund.id_found !== 2
                        ? formatNumber(Math.round(filterTwoFund(fund.id_found).start_total + sumTwoFundReceived(fund) - sumFundMonth(filterTwoFund(fund.id_found))))
                        : ''}}
                </td>

                <template v-if="fund" v-for="(month,index) in list_month">
                    <td v-if="fund.id_found === 1 || fund.id_found === 2"
                        class="py-2 px-2" v-bind:class="statusFundClass(fund.id_found)">
                        {{index === 6 ? parseFloat(oneFunds.find(x => x.id_found === fund.id_found)[month.en].toFixed(2)) + ' ' + parseFloat(twoFunds.find(x => x.id_found === fund.id_found)[month.en].toFixed(2))
                          : (index < 6 ? parseFloat(oneFunds.find(x => x.id_found === fund.id_found)[month.en].toFixed(2))
                            : (index > 6 ? (twoFunds.find(x => x.id_found === fund.id_found)[month.en] ? twoFunds.find(x => x.id_found === fund.id_found)[month.en].toFixed(2) : '' ) : '') )}}
                    </td>
                    <td v-else class="px-2">
                        {{fund.id_found === 3 && index === 6
                            ? valueRound(oneFunds.find(x => x.id_found === 2)[month.en]) + '/' + valueRound(twoFunds.find(x => x.id_found === 2)[month.en])
                            : (fund.id_found === 3 && (index < 6 || index > 6) ? valueRound((index < 6 ? oneFunds : twoFunds).find(x => x.id_found === 2)[month.en])

                        : ((fund.id_found === 5 || fund.id_found === 6) && index === 6
                            ? valueRound(oneFunds.find(x => x.id_found === 1)[month.en] * (oneFunds.find(x => x.id_found === 4).percent / 100) / 2) + '/' + valueRound(twoFunds.find(x => x.id_found === 1)[month.en] * (twoFunds.find(x => x.id_found === 4).percent / 100) / 2)
                            : ((fund.id_found === 5 || fund.id_found === 6) && (index < 6 || index > 6) ? valueRound((index < 6 ? oneFunds : twoFunds).find(x => x.id_found === 1 )[month.en] * ((index < 6 ? oneFunds : twoFunds).find(x => x.id_found === 4).percent / 100) / 2)

                                : (fund.id_found === 11 && index < 6 ? ''
                                    : (fund.id_found !== 11 && index === 6
                                        ? valueRound(oneFunds.find(x => x.id_found === 1)[month.en] * (oneFunds.find(x => x.id_found === fund.id_found).percent / 100)) + '/' + (valueRound(twoFunds.find(x => x.id_found === 1)[month.en] * ((twoFunds.find(x => x.id_found === fund.id_found).percent) / 100)) || 0)
                                        : valueRound((index < 6 ? oneFunds : twoFunds).find(x => x.id_found === 1)[month.en] * (((index < 6 ? oneFunds : twoFunds).find(x => x.id_found === fund.id_found).percent) / 100))
                                        )
                                    )
                                )
                            )
                        ) }}
                    </td>
                    <td>{{fund.id_found !== 1 && fund.id_found !== 2 && index === 6
                        ? (filterOneFund(fund.id_found) ? (valueRound(filterOneFund(fund.id_found)[month.en]) || '-') : '-')
                        + '/' + (filterTwoFund(fund.id_found) ? (valueRound(filterTwoFund(fund.id_found)[month.en]) || '-') : '')
                        : fund.id_found !== 1 && fund.id_found !== 2 && (index < 6 || index > 6)
                            ? valueRound(index < 6 ? (filterOneFund(fund.id_found) ? (valueRound(filterOneFund(fund.id_found)[month.en]) || '') : '') : (filterTwoFund(fund.id_found) ? (valueRound(filterTwoFund(fund.id_found)[month.en]) || '') : '') ) : ''}}</td>
                </template>
            </tr>
            <tr class="text-warning">
                <th class="text-start">ИТОГО</th>
                <th></th>

                <th class="table-style-1">{{formatNumber(total.start_all)}}</th>
                <th class="table-style-1">{{formatNumber(total.received_all)}}</th>
                <th class="table-style-1">{{formatNumber(total.spent_all)}}</th>
                <th class="table-style-1">{{formatNumber(total.total_all)}}</th>

                <th class="table-style-2">{{formatNumber(total.start_all_2)}}</th>
                <th class="table-style-2">{{formatNumber(total.received_all_2)}}</th>
                <th class="table-style-2">{{formatNumber(total.spent_all_2)}}</th>
                <th class="table-style-2">{{formatNumber(total.total_all_2)}}</th>

                <template v-for="(month,index) in list_month">
                    <th></th>
                    <th>{{index === 6 ? totalMonth(oneFunds, month.en) + '/' + totalMonth(twoFunds, month.en)
                            : ((index < 6 || index > 6) ? totalMonth(index < 6  ? oneFunds : twoFunds, month.en) : '')}}</th>
                </template>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Fund2024",
    props:['form','funds','list_month','total'],
    computed: {
        uniqFunds(){
            return this.funds.filter((lists, index, self) =>
                        self.findIndex(t => t.id_found === lists.id_found) === index)
                        // .map(x => [{'name': x.name,
                        //             'id_found':x.id_found,
                        //             'percent': x.percent,
                        //             'id_group': x.id_group,
                        //             'start_total': x.start_total}][0])
        },
        oneFunds(){
          return !!this.funds.length ? this.funds.filter(x => x.month_to === 5) : []
        },
        twoFunds(){
            return !!this.funds.length ? this.funds.filter(x => x.month_to === 11) : []
        }
    },
    methods:{
        filterOneFund(id_found){
          return this.oneFunds.find(x => x.id_found === id_found)
        },
        filterTwoFund(id_found){
          return this.twoFunds.find(x => x.id_found === id_found)
        },
        totalMonth(funds, month){
            let result = Math.round(funds.filter(x => x && x.visibility === '1')
                                              .reduce((partialSum, a) => partialSum + a[month], 0))
            return result ? this.formatNumber(result) : '';
        },
        sumOneFundReceived(fund){
            return (fund.id_found === 3 ? this.oneFunds.filter(x => x.id_found === 2).map(x => this.sumFundMonth(x))[0] :
                    (fund.percent ? (this.oneFunds.filter(x => x.id_found === 1).map(x => this.sumFundMonth(x))[0] / 100 * fund.percent)
                        : (this.oneFunds.filter(x => x.id_found === 1).map(x => this.sumFundMonth(x))[0] / 100
                            * this.oneFunds.filter(x => x.id_found === fund.id_group).map(x => x.percent) / 2))
                            // * this.oneFunds.filter(x => x.id_found === fund.id_group).map(x => x.percent) / 2))
            )
        },
        sumTwoFundReceived(fund){
            return (fund.id_found === 3 ? this.twoFunds.filter(x => x.id_found === 2).map(x => this.sumFundMonth(x))[0] :
                    (this.filterTwoFund(fund.id_found).percent ? (this.twoFunds.filter(x => x.id_found === 1).map(x => this.sumFundMonth(x))[0] / 100 * this.filterTwoFund(fund.id_found).percent)
                        : (this.twoFunds.filter(x => x.id_found === 1).map(x => this.sumFundMonth(x))[0] / 100
                            * this.twoFunds.filter(x => x.id_found === fund.id_group).map(x => x.percent) / 2))
            )
        },
        sumFundMonth(fund){
            if(fund){
                return fund.january +
                       fund.february +
                       fund.march +
                       fund.april +
                       fund.may +
                       fund.june +
                       fund.july +
                       fund.august +
                       fund.september +
                       fund.october +
                       fund.november +
                       fund.december
            }
        },

        statusFundClass(fund){
            return (fund === 1 || fund === 2) ? 'text-info' : ''
        },
        valueRound(val){
            return val ? (Math.sign(val) === -1 ? (- Math.round(val * -1)) : Math.round(val)) : ''
        }
    }
}
</script>

<style scoped>
.table-style-1{
    background: rgba(0,0,0,0.1);
}
.table-style-2{
    background: rgba(70,0,0,0.1);
}
</style>
