<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel" class="my-1">Подробная информация
                <div style="line-height: 0.7"><small class="text-muted font-size-12">COSWiCK Solid Hardwood Floors.</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div v-if="filterOS" class="offcanvas-body">
            <div class="card bg-light">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="lh-1"><small class="text-muted">{{filterOS.Poroda || 'По всем породам'}}</small></div>
                            <p class="text-truncate font-size-14 mb-2 text-primary">{{filterOS.GroupNomNaim || GROUP_ALL}}
                                <!--                                <i class="mdi" v-bind:class="filterOS.Elka === true ? 'mdi-pine-tree' : ''"/>-->
                            </p>
                            <h4 class="mb-1">{{formatNumber(filterOS.KolichestvoFact)}} м²
                                <span class="font-size-12"
                                      v-bind:class="filterOS.ProcentPrirosta > 0 ? 'text-success'
                                      : filterOS.ProcentPrirosta === 0 ? 'text-warning'
                                      : 'text-danger'">
                                    <i class="mdi font-size-14"
                                       v-bind:class="filterOS.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                       : filterOS.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{filterOS.ProcentPrirosta}} %
                                </span>
                            </h4>
                            <p class="text-muted font-size-13 mb-0">
                                <span class="fw-bold font-size-12 me-2"
                                      v-bind:class="filterOS.OtklonenieOtPlana > 0 ? 'text-success'
                                      : filterOS.OtklonenieOtPlana === 0 ? 'text-warning'
                                      : 'text-danger'">
                                <i class="me-1 align-middle"
                                   v-bind:class="filterOS.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                   : filterOS.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                   : 'ri-arrow-right-down-line'"></i>{{filterOS.OtklonenieOtPlana}}%</span>отношение к плану

                                <span class="font-size-12">/ Ср. цена: {{averagePrice()}} €/м²</span>
                            </p>
                            <p class="mb-0"></p>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>

            <div v-if="spinner_details" class="text-center py-4">
                <div class="spinner-border text-primary m-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div v-if="!!filterDetailsOrders.length" v-for="filter in filterDetailsOrders" class="card card-body bg-light">
                <h4 class="card-title">{{filter.KomSortNaim}}
                    <!--                    <i class="mdi" v-bind:class="filter.Elka === true ? 'mdi-pine-tree' : ''"/>-->
                </h4>
                <p class="card-text mb-0">Заказано: {{formatNumber(filter.KolichestvoFact)}} м²
                    <span class="font-size-12"
                          v-bind:class="filter.OtklonenieOtPlana > 0 ? 'text-success'
                          : filter.OtklonenieOtPlana === 0 ? 'text-warning'
                          : 'text-danger'">({{filter.OtklonenieOtPlana}} %)</span>
                </p>
                <p class="card-text mb-0">Произведено: {{filter.Produced}} м²
                    <span class="font-size-12"
                          v-bind:class="filter.OtklonenieOtPlanaProduced > 0 ? 'text-success'
                          : filter.OtklonenieOtPlanaProduced === 0 ? 'text-warning'
                          : 'text-danger'">({{filter.OtklonenieOtPlanaProduced}} %)</span>
                </p>
                <p class="card-text mb-0">Свободно: {{formatNumber(filter.Stock)}} м²
                    <!--<span class="font-size-12" v-bind:class="filter.OtklonenieOtPlanaStock > 0 ? 'text-success' : filter.OtklonenieOtPlanaStock === 0 ? 'text-warning' : 'text-danger'">({{filter.OtklonenieOtPlanaStock}} %)</span>--></p>
                <p class="card-text mb-0">Ср. цена: {{formatNumber(filter.PriceFact)}} м²
                    <span class="font-size-12"
                          v-bind:class="filter.OtklonenieOtPlanaCena > 0 ? 'text-success'
                          : filter.OtklonenieOtPlanaCena === 0 ? 'text-warning'
                          : 'text-danger'">({{filter.OtklonenieOtPlanaCena}} %)</span>
                </p>
                <p class="card-text mb-0">To Base Price: {{filter.BasePrice}} €/м²</p>
                <hr>
                <h6 class="text-info mb-0">Баланс:
                    {{ (filter.Produced > 0) ? formatNumber((filter.KolichestvoFact - filter.Produced)/filter.Produced) : ''}}
                </h6>
            </div>

            <div class="card card-body">
                <apexchart v-show="!!seriesDetails.length" type="donut"
                           :options="chartOptionsDetails"
                           :series="seriesDetails"></apexchart>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Details",
    props:['GROUP_ALL', 'filterOS', 'spinner_details', 'filterDetailsOrders', 'seriesDetails', 'chartOptionsDetails'],
    methods:{
        averagePrice(){
            return this.filterDetailsOrders.reduce((a,b) => (parseFloat(a) + (parseFloat(b.KolichestvoFact) * parseFloat(b.PriceFact)) / this.filterOS.KolichestvoFact).toFixed(2),0 ) //  * parseFloat(b.PriceFact) / filterOS.KolichestvoFact
        }
    }
}
</script>

<style scoped>

</style>
