<template>
    <div :role="role ? 'button' : ''" class="col-12 col-sm-4"
         :onclick="click">
        <div class="row justify-content-center my-1">
            <div class="col-lg-12 px-1">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="border p-3 text-center rounded">
                            <img class="card-img-top img-fluid" :src="img"
                                 style="object-fit: cover; height: 170px;opacity: 0.4" alt="COSWiCK">
                            <div class="carousel-caption text-white-50">
                                <h6 class="text-white float-end">{{name}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BtnComponent",
    props:['name','role','img','click']
}
</script>

<style scoped>

</style>
