<template>
    <div class="row">
        <div class="col-12">
        <div class="d-sm-flex align-items-center justify-content-between">
            <button @click="clearForm" type="button" class="btn btn-outline-dark waves-effect waves-light mb-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <i class="ri-user-add-line align-middle me-2"></i>Зарегистрировать пользователя</button>
            <div class="page-title-right">
                <form class="app-search d-none d-lg-block">
                    <div class="position-relative bg-light" style="border-radius: 10px">
                        <input v-model="search" type="text" class="form-control" placeholder="Поиск...">
                        <span class="ri-search-line"></span>
                    </div>
                </form>
            </div>
        </div>
        </div>
    </div>
<!--    <div class="row">-->
<!--        <button type="button" class="btn btn-outline-light waves-effect ms-1 col-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">-->
<!--            <span class="d-none d-sm-block">Добавить пользователя</span><i class="mdi mdi-delete d-block d-sm-none"></i>-->
<!--        </button>-->
<!--    </div>-->

    <div class="row">
        <div v-for="user in filtersUsers" class="col-lg-6 col-xxl-4 p-0 p-sm-2">
            <div class="card mb-0">
                <div class="card-body border">
                    <div class="float-end">
                        <div class="dropdown card-header-dropdown">
                            <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text-muted"><i class="mdi mdi-chevron-down ms-1"></i></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" style="">
                                <a @click="initializationUser(user)" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticReset" href="#">Сменить пароль</a>
                                <a @click="updateUser(user)" class="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#">Редактировать</a>
                                <a @click="delete_id_user = user.id" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticBackdrop" href="#">Удалить</a>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <img class="d-flex me-3 rounded-circle img-thumbnail avatar-lg d-none d-sm-block" :src="user.photo ? '/assets/images/users/'+user.photo : ''" :alt="user.name">
                        <div class="flex-grow-1">
                            <small>{{user.last_online_at ? formatDateDMYHMS(user.last_online_at) : ''}}</small>
                            <h5 class="mt-0 font-size-18 mb-1 ">{{user.fio}}</h5>
                            <small class="text-muted font-size-14">{{user.email}}</small><br>
                            <small class="text-muted font-size-14">{{user.position}}</small>
<!--                            <ul class="social-links list-inline mt-2">-->
<!--                                <button @click="delete_id_user = user.id" type="button" class="btn btn-outline-light waves-effect ms-1 float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span class="d-none d-sm-block">Удалить</span><i class="mdi mdi-delete d-block d-sm-none"></i></button>-->
<!--                                <button @click="updateUser(user)" type="button" class="btn btn-outline-light waves-effect float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><span class="d-none d-sm-block">Редактировать</span><i class="mdi mdi-account-edit-outline d-block d-sm-none"></i></button>-->
<!--                            </ul>-->
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Удаление пользователя</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Вы действительно хотите удалить данного пользователя?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light waves-effect" data-bs-dismiss="modal">Отмена</button>
                    <button @click="deleteUser(user)" type="button" class="btn btn-primary waves-effect waves-light" data-bs-dismiss="modal">Удалить</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="staticReset" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticResetLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticResetLabel">Сменить пароль</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mt-3 mb-3">
                        <label for="password" class="col-md-4 col-form-label text-md-end">Password</label>

                        <div class="col-md-7">
                            <input v-model="form_user.password" id="password" type="password" class="form-control" name="password" required autocomplete="new-password">
                        </div>
                    </div>

                    <div class="row">
                        <label for="password-confirm" class="col-md-4 col-form-label text-md-end">Confirm Password</label>

                        <div class="col-md-7">
                            <input v-model="form_user.confirm_password" id="password-confirm" type="password" class="form-control" name="password_confirmation" required autocomplete="new-password">
                        </div>
                    </div>
                    <div class="row border-top mt-4 pb-1">
                        <div class="col-md-12 mx-3 font-size-13">
                            <div class="mt-4">
                                <div class="text-warning pb-1">Требования к паролю:</div>
                                <div>1. Не менее 8 символов.</div>
                                <div>2. Как минимум одна заглавная и одна строчная буква.</div>
                                <div>3. Как минимум одна цифра.</div>
<!--                                <div>4. Как минимум один символ.</div>-->
                                <div>4. Проверка пароля на утечку общедоступных данных паролей.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light waves-effect" data-bs-dismiss="modal">Отмена</button>
                    <button @click="updatePassword" type="button" class="btn btn-primary waves-effect waves-light">Сохранить</button>
                </div>
            </div>
        </div>
    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">{{form_user.id ? EDIT_USER : USER_REGISTRATION}}
                <div class="text-muted font-size-12">Coswick Solid Hardwood Floors</div></h5>

            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="card card-body bg-light">
                <div class="mb-2">
                    <label class="form-label" for="photo">Фото пользователя: </label>
                    <input @change="onPhotoChange" type="file" ref="filePhoto" id="photo" class="dropify" data-bs-height="180" />
                    <img v-if="form_user.photo_old" :src="'/assets/images/users/'+form_user.photo_old" alt="avatar-3" class="rounded avatar-sm">
                </div>
                <div class="mb-2">
                    <label class="form-label" for="name">Имя пользователя:</label>
                    <input v-model="form_user.name" class="form-control" type="text" placeholder="Имя пользователя" id="name">
                </div>
                <div class="mb-2">
                    <label class="form-label" for="fio">ФИО:</label>
                    <input v-model="form_user.fio" class="form-control" type="text" placeholder="ФИО" id="fio">
                </div>
                <div class="mb-2">
                    <label class="form-label" for="position">Должность:</label>
                    <input v-model="form_user.position" class="form-control" type="text" placeholder="Должность" id="position">
                </div>
                <div class="mb-2">
                    <label class="form-label" for="example-email-input">E-mail:</label>
                    <input v-model="form_user.email" class="form-control" type="email" placeholder="...@coswick.org" id="example-email-input">
                </div>

                <div class="mb-2">
                    <label class="form-label" for="example-email-input">Роли:</label>
                    <vSelect class="form-control" multiple v-model="form_user.roles" :options="roles_list" :reduce="role => role.name" label="name"/>
                </div>

                <div class="form-check form-switch mb-3" dir="ltr">
                    <input @change="!form_user.change_password" type="checkbox" class="form-check-input" :id="'for-1'" v-model="form_user.change_password">
                    <label class="form-check-label" :for="'for-1'">Уведомление сменить пароль</label>
                </div>

                <button @click="saveUserForm" class="btn btn-primary mt-3" data-bs-dismiss="offcanvas" aria-label="Close">Сохранить</button>

            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

const USER_REGISTRATION = 'Регистрация пользователя'
const EDIT_USER = 'Редактировать пользователя'

export default {
    name: "UsersComponent",
    components:{vSelect},
    data:()=>({
        USER_REGISTRATION:USER_REGISTRATION,
        EDIT_USER:EDIT_USER,
        users:[],
        roles_list:[],
        search:'',

        delete_id_user:null,

        form_user:{
            id:null,
            photo_old:null,
            photo:null,
            name:null,
            fio:null,
            position:null,
            email:null,
            roles:null,
            password:null,
            confirm_password:null,
            change_password:null,
        }
    }),
    computed: {
        filtersUsers(){
            return (this.search) ? this.users.filter(user => {
                                    return user.name.toLowerCase().includes(this.search.toLowerCase())
                                        || user.fio.toLowerCase().includes(this.search.toLowerCase())
                                        || user.email.toLowerCase().includes(this.search.toLowerCase())
                                    }) : this.users
        }
    },
    mounted() {
        this.searchUsersProfile()
        // this.searchUsers()
    },
    methods:{
        clearForm(){
            Object.assign(this.$data.form_user,this.$options.data().form_user)
            this.$refs.filePhoto.value=null
        },

        onPhotoChange(file){
            this.form_user.photo = file.target.files[0]
        },

        searchUsersProfile(){
            axios.get('/administration/settings/search-users-profile')
                .then(response => {
                    this.users = response.data['users']
                    this.roles_list = response.data['roles']
                }).catch(error => {
                console.log(error);
            }).finally(() => this.preloaderClose())
        },

        initializationUser(user){
            this.$refs.filePhoto.value=null
            this.form_user.id = user.id
            this.form_user.photo_old = user.photo
            this.form_user.photo = user.photo
            this.form_user.name = user.name
            this.form_user.fio = user.fio
            this.form_user.email = user.email
            this.form_user.position = user.position
            this.form_user.change_password = user.change_password === 1
        },

        updateUser(user){
            this.initializationUser(user)

            axios.post('/administration/settings/get-modal-users-and-roles',{id:user.id}).then(response => {
                this.form_user.roles = response.data['user_roles']
                this.roles_list = response.data['roles']
                }).catch(error => {
                    console.log(error);
                });
        },

        updatePassword(){
            if(this.form_user.password === this.form_user.confirm_password){
                axios.post('/administration/settings/update-password',this.form_user).then(response => {
                    if(response.data){
                        this.clearForm()
                        $('#staticReset').modal('hide')
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },

        saveUserForm(){
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
            axios.post('/administration/settings/save-user-form',this.form_user,config)
                .then(response => {
                    this.users = response.data
                }).catch(error => {
                    console.log(error);
                });
        },

        deleteUser(){
            axios.post('/administration/settings/delete-user',{id:this.delete_id_user})
                .then(response => {
                    this.users = response.data
                }).catch(error => {
                console.log(error);
            })
        }
    },
}
</script>

<style scoped>
@media (min-width: 1400px) and (max-width: 1700px) {
    .col-xxl-4{
        width: 50% !important;
    }
}
</style>

<style>
.vs__dropdown-toggle{
    background: none !important;
}
</style>
