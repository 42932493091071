<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasCounterparties" aria-labelledby="offcanvasCounterpartiesLabel">
        <div class="offcanvas-header">
            <h5 class="my-1" id="offcanvasCounterpartiesLabel">Список контрагентов
                <div style="line-height: 0.7"><small class="text-muted font-size-12">Coswick Solid Hardwood Floors</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div v-if="filterSales" class="offcanvas-body">
            <div class="card border">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1 text-primary">{{filterSales.Region || REGION_ALL}}
                                <div class="lh-1"><small class="text-muted">{{specificGravity(filterSales)}}% - Удельный вес</small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(filterSales.KolichestvoFact)}} м²
                                <span class="font-size-12"
                                      v-bind:class="filterSales.ProcentPrirosta > 0 ? 'text-success'
                                      : filterSales.ProcentPrirosta === 0 ? 'text-warning'
                                      : 'text-danger'">
                                    <i class="mdi font-size-14"
                                       v-bind:class="filterSales.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                       : filterSales.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{filterSales.ProcentPrirosta}} %
                                </span>
                            </h4>
                            <p class="text-muted mb-0">
                                <span class="fw-bold font-size-12 me-1"
                                      v-bind:class="filterSales.OtklonenieOtPlana > 0 ? 'text-success'
                                      : filterSales.OtklonenieOtPlana === 0 ? 'text-warning'
                                      : 'text-danger'">
                                <i class="me-1 align-middle"
                                   v-bind:class="filterSales.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                   : filterSales.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                   : 'ri-arrow-right-down-line'"></i>{{filterSales.OtklonenieOtPlana}}%</span>
                                <span class="font-size-12">- план {{filterSales.KolichestvoPlan}} м²</span>

                            </p>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>

            <div v-for="filter in filterCounterpartySales" class="card bg-light">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-12 mb-1 text-primary">{{filter.Kontragent}}
                                <div class="lh-1"><small class="text-muted">{{filter.TipCen}}</small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(filter.KolichestvoFact)}} м²
                                <span class="font-size-12"
                                      v-bind:class="filter.ProcentPrirosta > 0 ? 'text-success'
                                      : filter.ProcentPrirosta === 0 ? 'text-warning'
                                      : 'text-danger'">
                                    <i class="mdi font-size-14"
                                       v-bind:class="filter.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                       : filter.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{filter.ProcentPrirosta}} %
                                </span>
                            </h4>
                            <p class="text-muted mb-0">
                                <span class="fw-bold font-size-12 me-1"
                                      v-bind:class="filter.OtklonenieOtPlana > 0 ? 'text-success'
                                      : filter.OtklonenieOtPlana === 0 ? 'text-warning'
                                      : 'text-danger'">
                                <i class="me-1 align-middle"
                                   v-bind:class="filter.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                   : filter.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                   : 'ri-arrow-right-down-line'"></i>{{filter.OtklonenieOtPlana}}%</span>
                                <span class="font-size-12">- план {{filter.KolichestvoPlan}} м²</span>
                            </p>
                        </div>
                        <div v-show="formFilter.filter_category !== 'OrdersBalance'" class="avatar-sm">
                            <a @click="$emit('counterpartySales',filter)" href=""
                               class="avatar-title bg-body bg-opacity-25 text-primary rounded-3"
                               data-bs-toggle="offcanvas" data-bs-target="#offcanvasDetail" aria-controls="offcanvasDetail">
                                <i class="ri-equalizer-line font-size-24"></i>
                            </a>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Counterparties",
    props:['REGION_ALL', 'filterSales', 'filterCounterpartySales','formFilter','specificGravity'],
    methods:{
    }
}
</script>

<style scoped>

</style>
