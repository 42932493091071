<template>
    <div class="row px-0">
        <h6 class="col-12 pb-2 text-muted">Информация на текущий день.</h6>

        <div v-for="list in list_funds_region" class="col-xxl-3 col-xxl-mac-3 col-md-6">
            <div class="card" v-bind:class="list.Region || 'total'">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div v-if="list.Region === 'Китай'" @click="fundsRegionCompany(list)" class="pb-2 text-info lh-sm text-uppercase" role="button"
                                 data-bs-toggle="offcanvas" data-bs-target="#offcanvasDetail" aria-controls="offcanvasDetail">
                                {{list.Region ? (list.Region === 'Европа и Канада' ? EUROPA : list.Region) : 'ПО ВСЕМ РЕГИОНАМ'}}
                                <i class="mdi mdi-account-convert ps-1"></i>

                            </div>
                            <div v-else class="pb-2 text-info lh-sm text-uppercase">
                                {{list.Region ? (list.Region === 'Европа и Канада' ? EUROPA : list.Region) : 'ПО ВСЕМ РЕГИОНАМ'}}
                            </div>
                            <h6 class="mb-2">
                                <span class="text-muted">BALANCE: </span>
                                <span class="text-warning">{{formatNumber(list.SummaOstEUR)}} <span class="text-muted font-size-12">EUR</span></span>
                            </h6>
                            <p class="text-muted mb-0 font-size-10">
                                BYN <i class="mdi mdi-arrow-left-right"></i> EUR: <span class="text-success font-size-10">{{kurs_eur}}</span>
                            </p>
                        </div>

                        <div @click="fundsRegion(list)" role="button" class="avatar-sm">
                            <div class="avatar-title bg-transparent border rounded-3 px-3"
                                 data-bs-toggle="offcanvas" data-bs-target="#offcanvasDetail" aria-controls="offcanvasDetail">
                                <i class="ri-equalizer-line font-size-22"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasDetail" aria-labelledby="offcanvasDetailLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasDetailLabel" class="my-1">Подробная информация
                <div style="line-height: 0.7"><small class="text-muted font-size-12">Coswick Solid Hardwood Floors</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="card bg-light" v-bind:class="select_list_funds.Region || 'total'">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="pb-2 text-info lh-sm text-uppercase">{{select_list_funds.Region ? (select_list_funds.Region === 'Европа и Канада' ? EUROPA : select_list_funds.Region) : 'ПО ВСЕМ РЕГИОНАМ'}}</div>
                            <h6 class="mb-2">
                                <span class="text-muted">BALANCE: </span>
                                <span class="text-warning">{{formatNumber(select_list_funds.SummaOstEUR)}} <span class="text-muted font-size-12">EUR</span></span>
                            </h6>
                            <p class="text-muted mb-0 font-size-10">
                                BYN <i class="mdi mdi-arrow-left-right"></i> EUR: <span class="text-success font-size-10">{{kurs_eur}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="funds_region_detail" v-for="list in funds_region_company">
                <div class="card" v-bind:class="list.Valuta || 'total'">
                    <div class="card-body border">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <div class="pb-2 text-info lh-sm">{{list.Company || 'ПО ВСЕМ СЧЕТАМ'}}</div>
                                <h6 class="mb-2">
                                    <span>{{formatNumber(list.Valuta ? list.SummaOst : list.SummaOstBYN)}}</span> <span class="text-muted font-size-12">{{list.Valuta || 'BYN'}}</span>
                                    <i class="fas fa-long-arrow-alt-right text-success px-2"></i>
                                    <span class="text-warning">{{formatNumber(list.SummaOstEUR)}} <span class="text-muted font-size-12">EUR</span></span>
                                </h6>
                                <p class="text-muted mb-0 font-size-11">
                                    <span v-if="list.Valuta !== 'BYN'">{{list.Valuta }}</span>
                                    <i v-if="list.Valuta !== '' && list.Valuta !== 'BYN'" class="mdi mdi-arrow-right"></i>
                                    <span v-if="list.Valuta !== '' && list.Valuta !== 'BYN' && list.Valuta !== 'EUR'" class="text-success font-size-10">{{list.Kurs}}</span>
                                    <i v-if="list.Valuta !== '' && list.Valuta !== 'BYN' && list.Valuta !== 'EUR'" class="mdi mdi-arrow-right"></i>
                                    <span v-if="list.Valuta !== 'EUR'">BYN</span>
                                    <i v-if="list.Valuta !== 'EUR'" class="mdi mdi-arrow-right"></i>
                                    <span class="text-success font-size-10">{{list.KursEur}}</span>
                                    <i class="mdi mdi-arrow-right"></i> EUR
                                </p>
                            </div>

                            <div class="avatar-sm">
                                <div class="avatar-title bg-transparent border rounded-3 px-3">
                                    <span v-if="list.Valuta === 'BYN'">Br</span>
                                    <span v-else-if="list.Valuta === 'CAD'">CA$</span>
                                    <span v-else-if="list.Valuta === ''">ALL</span>
                                    <i v-else class="mdi font-size-22" v-bind:class="
                                list.Valuta === 'EUR' ? 'mdi-currency-eur'
                                : list.Valuta === 'USD' ? 'mdi-currency-usd'
                                : list.Valuta === 'RUR' ? 'mdi-currency-rub'
                                : list.Valuta === 'CNY' ? 'mdi-currency-cny'
                                : ''"></i>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else v-for="list in filterCounterpartySales" >
                <div class="card" v-bind:class="list.Valuta || 'total'">
                    <div class="card-body border">
                        <div class="d-flex">
                            <div class="flex-grow-1">
<!--                            <div class="text-truncate font-size-11 mb-0 lh-sm">{{list.Valuta ? 'Валюта' : 'Итого'}}:</div>-->
                                <div class="pb-2 text-info lh-sm">{{list.Valuta || 'ПО ВСЕМ СЧЕТАМ'}}</div>
                                <h6 class="mb-2">
<!--                                <span v-bind:class="list.KursEur === 1 ? 'text-warning' : ''">{{formatNumber(list.SummaOst)}}</span> <span class="text-muted font-size-15">{{list.Valuta}}</span>-->
<!--                                <i class="fas fa-long-arrow-alt-right text-success px-2"></i>-->
                                    <span>{{formatNumber(list.Valuta ? list.SummaOst : list.SummaOstBYN)}}</span> <span class="text-muted font-size-12">{{list.Valuta || 'BYN'}}</span>
                                    <i class="fas fa-long-arrow-alt-right text-success px-2"></i>
                                    <span class="text-warning">{{formatNumber(list.SummaOstEUR)}} <span class="text-muted font-size-12">EUR</span></span>
                                </h6>
                                <p class="text-muted mb-0 font-size-11">
                                    <span v-if="list.Valuta !== 'BYN'">{{list.Valuta }}</span>
                                    <i v-if="list.Valuta !== '' && list.Valuta !== 'BYN'" class="mdi mdi-arrow-right"></i>
                                    <span v-if="list.Valuta !== '' && list.Valuta !== 'BYN' && list.Valuta !== 'EUR'" class="text-success font-size-10">{{list.Kurs}}</span>
                                    <i v-if="list.Valuta !== '' && list.Valuta !== 'BYN' && list.Valuta !== 'EUR'" class="mdi mdi-arrow-right"></i>
                                    <span v-if="list.Valuta !== 'EUR'">BYN</span>
                                    <i v-if="list.Valuta !== 'EUR'" class="mdi mdi-arrow-right"></i>
                                    <span class="text-success font-size-10">{{list.KursEur}}</span>
                                    <i class="mdi mdi-arrow-right"></i> EUR
                                </p>
<!--                                <span v-if="list.Kurs && list.Valuta !== 'BYN'">{{parseFloat((list.Kurs   / (list.KursEur * list.Kratnost)).toFixed(4))}}</span><span v-else>{{list.KursEur}}</span>-->
<!--                            <p class="text-muted mb-0 font-size-11">Курс: <span class="text-success">{{kurs_eur}}</span> <span class="px-1">/</span> {{list.Company ? 'Все счета компании.' : 'Все счета компаний.' }}</p>-->
                            </div>

                            <div class="avatar-sm">
                                <div class="avatar-title bg-transparent border rounded-3 px-3">
                                    <span v-if="list.Valuta === 'BYN'">Br</span>
                                    <span v-else-if="list.Valuta === 'CAD'">CA$</span>
                                    <span v-else-if="list.Valuta === ''">ALL</span>
                                    <i v-else class="mdi font-size-22" v-bind:class="
                                list.Valuta === 'EUR' ? 'mdi-currency-eur'
                                : list.Valuta === 'USD' ? 'mdi-currency-usd'
                                : list.Valuta === 'RUR' ? 'mdi-currency-rub'
                                : list.Valuta === 'CNY' ? 'mdi-currency-cny'
                                : ''"></i>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
const EUROPA = 'Европа';

export default {
    name: "PersonalAccountsComponent",
    data:()=>({
        EUROPA:EUROPA,
        list_funds:[],
        list_funds_region:[],

        kurs_eur:0,
        select_list_funds:[],
        // filter_personal_accounts:[],
        funds_region_detail:false,
        funds_region_company:[],
    }),
    mounted() {
        this.search()
    },
    computed:{
        filterCounterpartySales(){
            return this.list_funds.filter(region => {
                return ((this.select_list_funds && this.select_list_funds.Region !== '')
                    ? (region.Region === this.select_list_funds.Region && region.Region !== '')
                    : this.select_list_funds.Region === '' ? 1 : [] )
            })
        },
    },
    methods:{
        search(){
            axios.post('/analytics/funds/personal-accounts/search').then(response => {
                // this.list_funds = response.data['data'].filter(x => x.Region ==='').sort((a, b) => (a.Valuta || "z").localeCompare((b.Valuta || "z")))
                // this.list_funds_region = response.data['data'].filter(x => x.Region !=='').sort((a, b) => (a.Valuta || "z").localeCompare((b.Valuta || "z")))

                this.list_funds = response.data['data'].filter(x => x.Valuta && x.Region !== '').sort((a, b) => (a.Valuta || "z").localeCompare((b.Valuta || "z")))
                this.list_funds_region = response.data['data'].filter(x => x.Valuta).reduce((a, b) => {
                   const item = a.find(({Region}) => Region === b.Region)
                    item ? item.SummaOstEUR += b.SummaOstEUR : a.push({Region: b.Region, SummaOstEUR:b.SummaOstEUR})
                    return a;
                },[])
                    .sort((a, b) => (a.Region || "z").localeCompare((b.Region || "z")))

                this.kurs_eur = this.list_funds.reduce((a, b) => a.KursEur > b.KursEur ? a.KursEur : b.KursEur)
            }).catch(error => {
            }).finally(() => {
                this.preloaderClose()
            });
        },
        fundsRegion(list){
            this.funds_region_detail = false
            this.funds_region_company = []
            this.select_list_funds = list
        },
        fundsRegionCompany(list){
            this.funds_region_detail = true
            this.funds_region_company = []
            this.select_list_funds = list
                axios.post('/analytics/funds/personal-accounts/search-company').then(response => {
                    this.funds_region_company = response.data['data']
                }).catch(error => {})

        }
    }
}
</script>

<style scoped>
</style>
