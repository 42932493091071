<template>
<!--    <div class="row">-->
<!--        <div class="col-12">-->
<!--            <div class="row">-->
<!--                <div class="col-12 col-md-6">-->
<!--                    <span class="float-start text-secondary">-->
<!--                        <strong>Coswick. Inspire</strong>-->
<!--                        <div v-show="formFilter.filter_category !== 'OrdersBalance'" v-if="formFilter.filter_period_start && formFilter.filter_period_end" class="mt-sm-0 mb-sm-1">-->
<!--                            {{formatDateDMY(formFilter.filter_period_start)}} - {{formatDateDMY(formFilter.filter_period_end)}}-->
<!--                        </div>-->
<!--                    </span>-->
<!--                </div>-->

<!--                <div class="col-12 col-md-6 mt-2 mb-3">-->
<!--                    <FilterMTMComponent :search = search :formFilter="formFilter" :filter_mtm="filter_mtm" />-->
<!--                    <FilterDTDComponent :search = search :filter_dtd = filter_dtd :formFilter="formFilter" />-->
<!--                    <FilterPeriodComponent v-show="formFilter.filter_category !== 'OrdersBalance'" :formFilter="formFilter" :selectPeriod="selectPeriod" />-->
<!--                    <FilterCategoryComponent v-show="formFilter.filter_category" :search = search :formFilter="formFilter" />-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
    <div class="d-flex">
        <span class="float-start text-secondary mb-2">
            <strong>Coswick. Inspire</strong>
            <div class="font-size-11" v-show="formFilter.filter_category !== 'OrdersBalance'" v-if="formFilter.filter_period_start && formFilter.filter_period_end">
                {{formatDateDMY(formFilter.filter_period_start)}} - {{formatDateDMY(formFilter.filter_period_end)}}
            </div>
        </span>
        <div class="ms-auto mb-2 d-flex">
            <FilterCategoryComponent v-show="formFilter.filter_category" :search = search :formFilter="formFilter" />
            <FilterPeriodComponent v-show="formFilter.filter_category !== 'OrdersBalance'" :formFilter="formFilter" :selectPeriod="selectPeriod" />
            <FilterMTMComponent :search = search :formFilter="formFilter" :filter_mtm="filter_mtm" />
            <FilterDTDComponent :search = search :filter_dtd = filter_dtd :formFilter="formFilter" />
        </div>

    </div>

</template>

<script>
import FilterMTMComponent from "./FilterMTMComponent.vue";
import FilterDTDComponent from "./FilterDTDComponent.vue";
import FilterPeriodComponent from "./FilterPeriodComponent.vue";
import FilterCategoryComponent from "./FilterCategoryComponent.vue";
export default {
    name: "SelectFilterComponent",
    props:['formFilter','search'],
    components:{FilterMTMComponent,FilterDTDComponent,FilterPeriodComponent,FilterCategoryComponent},
    data:()=>({
        filter_mtm:{
            mtm:false,
            select_month:null,
        },
        filter_dtd:{
            dtd:false,
            date_start:null,
            date_end:null,
        },
    }),
    methods:{
        selectPeriod(){
            Object.assign(this.$data.filter_mtm, this.$options.data().filter_mtm)
            Object.assign(this.$data.filter_dtd, this.$options.data().filter_dtd)

            if(this.formFilter.filter_period === 'MTM'){
                this.filter_mtm.mtm = true
                this.clearPeriod()
            }else if(this.formFilter.filter_period === 'DTD'){
                this.filter_dtd.dtd = true
                this.clearPeriod()
            }else{
                this.search()
            }
        },
        clearPeriod(){
            this.formFilter.filter_period_start = null
            this.formFilter.filter_period_end = null
        }
    }
}
</script>

<style scoped>

</style>
