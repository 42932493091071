<template>

</template>

<script>
export default {
    name: "Currency",
    methods:{
        currency(val){
            return (val === 'EUR' || val === 'евро') ? '€'
                    : val === 'USD' ? '$'
                    : val === 'CAD' ? 'CA$'
                    : val === 'руб.' ? '₽'
                    : val === 'BYN' ? 'Br'
                    : '';
        },

        signCurrency(currency, price, decoding = ''){
            return (currency === 'EUR' || currency === 'евро') ? '€' + price //+ ' EUR'
                    : currency === 'USD' ? '$' + price //+ ' USD'
                    : currency === 'руб.' ? (decoding ? decoding+':':'') +' '+ price + ' '+ '₽'
                    : currency === 'BYN' ? (decoding ? decoding+':':'') +' '+ price + ' ' + 'Br'
                    : currency === 'CNY' ? (decoding ? decoding+':':'') +' '+ price + ' ' + '¥'
                    : '';
        }
    }
}
</script>

<style scoped>

</style>
