<template>
    <div class="row px-0">

        <div class="col-12 col-md-12 mt-2 mb-3">
            <span class="float-start text-secondary">
                        <strong>Coswick. Inspire</strong>
<!--                        <div v-show="formFilter.filter_category !== 'OrdersBalance'" v-if="formFilter.filter_period_start && formFilter.filter_period_end" class="mt-sm-0 mb-sm-1">-->
<!--                            {{formatDateDMY(formFilter.filter_period_start)}} - {{formatDateDMY(formFilter.filter_period_end)}}-->
<!--                        </div>-->
                    </span>
            <FilterMTMComponent :search = search :formFilter="formFilter" :filter_mtm="filter_mtm" />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4 col-xxl-3 col-xxl-mac-3 px-2" v-for="user in users.sort((a,b) => b.Oklad - a.Oklad)"
             role="button" data-bs-toggle="offcanvas" @click="userDetails(user)"
             href="#offcanvasExample" aria-controls="offcanvasExample">
            <div class="card mb-3 border">
                <div class="card-body">

                    <div class="d-flex align-items-center">
<!--                        <img class="d-flex me-3 rounded-circle img-thumbnail avatar-lg" src="" alt="img">-->
                        <div class="flex-grow-1">
                            <div class="text-center">
                                <small class="text-warning">{{user.Podrazdelenie}}</small>
                                <h5 class="mt-0 font-size-17 mb-0">{{user.Sotrudnik}}</h5>
                                <p class="text-muted font-size-11 mb-2 lh-2">{{user.Doljnost}}</p>
                            </div>
                            <hr>

                            <div class="row font-size-13 mb-2">
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Оклад </p>
                                        <h6 class="mb-0 font-size-11">{{formatNumber(user.Oklad)}} BYN</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Премия</p>
                                        <h6 class="mb-0 font-size-11">{{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? (user.KPI5 ? kpi(user.KPI5)+'%' : '-') : (user.KPI4 ? kpi(user.KPI4)+'%' : '-')}}</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Кв. бонус</p>
                                        <h6 class="mb-0 font-size-11">{{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? formatNumber(user.Fakt6) : formatNumber(user.Fakt5)}}%</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Итого</p>
                                        <h6 class="mb-0 text-success font-size-11">{{user.ITOGO}} BYN</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> <!-- end col -->
    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 class="my-1" id="offcanvasExampleLabel">KPI Сотрудника
                <div style="line-height: 0.7"><small class="text-muted font-size-12">Coswick Solid Hardwood Floors</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="text-center">
                            <small class="text-warning">{{user_details.Podrazdelenie}}</small>
                            <h5 class="mt-0 font-size-17 mb-1">{{user_details.Sotrudnik}}</h5>
                            <p class="text-muted font-size-12 mb-2 lh-1">{{user_details.Doljnost}}</p>
                                <div v-if="user_details.Region" class="text-info font-size-11 lh-1">{{user_details.Region}}</div>

                            </div>

                            <hr class="mt-3 mb-2">
                            <div class="row font-size-13 mb-2">
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Оклад </p>
                                        <h6 class="mb-0 font-size-11">{{formatNumber(user_details.Oklad)}} BYN</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Премия</p>
                                        <h6 class="mb-0 font-size-11">{{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? (user_details.KPI5 ? kpi(user_details.KPI5)+'%' : '-') : (user_details.KPI4 ? kpi(user_details.KPI4)+'%' : '-')}}</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Кв. бонус</p>
                                        <h6 class="mb-0 font-size-11">{{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? formatNumber(user_details.Fakt6) : formatNumber(user_details.Fakt5)}}%</h6>
                                    </div>
                                </div>
                                <div class="col-3 px-1">
                                    <div class="text-center mt-0">
                                        <p class="mb-1 text-truncate">Итого</p>
                                        <h6 class="mb-0 text-success font-size-11">{{user_details.ITOGO}} BYN</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel1}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Вес:</span> {{user_details.VesPokazatel1}} %</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+user_details.VesPokazatel1+'%'" :aria-valuenow="user_details.VesPokazatel1" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div class="font-size-12 mb-1"><span class="me-1">План:</span> {{user_details.Plan1}} % \ Факт: {{user_details.Fakt1}} %</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.Fakt1 / user_details.Plan1)*100) + '%'" :aria-valuenow="((user_details.Fakt1 / user_details.Plan1)*100)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div class="font-size-12 mb-1"><b><span class="me-1">KPI #1:</span> {{user_details.KPI1 ? kpi(user_details.KPI1)+ '%' : '-'}}</b></div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.KPI1+'%'" :aria-valuenow="user_details.KPI1" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel2}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Вес:</span> {{user_details.VesPokazatel2}} %</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.VesPokazatel2+'%'" :aria-valuenow="user_details.VesPokazatel2" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

<!--                            <div><span class="me-1">План:</span> {{formatNumber(user_details.Plan2)}} м²</div>-->
                            <div class="font-size-12 mb-1">План: {{formatNumber(user_details.Plan2)}} {{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? 'EUR' : 'м²'}} \ Факт: {{formatNumber(user_details.Fakt2)}} {{filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024 ? 'EUR' : 'м²'}}</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.Fakt2 / user_details.Plan2)*100) + '%'" :aria-valuenow="((user_details.Fakt2 / user_details.Plan2)*100)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

<!--                            <div><span class="me-1">Факт:</span> {{formatNumber(user_details.Fakt2)}} м²</div>-->

                            <div class="font-size-12 mb-1"><span class="me-1">KPI #2:</span> {{user_details.KPI2 ? kpi(user_details.KPI2)+'%' : '-'}}</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.KPI2+'%'" :aria-valuenow="user_details.KPI2" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel3}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Вес:</span> {{user_details.VesPokazatel3}} %</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.VesPokazatel3+'%'" :aria-valuenow="user_details.VesPokazatel3" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div class="font-size-12 mb-1"><span class="me-1">План:</span> {{formatNumber(user_details.Plan3)}} м² \ Факт: {{formatNumber(user_details.Fakt3)}} м²</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.Fakt3 / user_details.Plan3)*100) + '%'" :aria-valuenow="((user_details.Fakt3 / user_details.Plan3)*100)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
<!--                            <div><span class="me-1">Факт:</span> {{formatNumber(user_details.Fakt3)}} м²</div>-->
                            <div class="font-size-12 mb-1"><span class="me-1">KPI #3:</span> {{user_details.KPI3 ? kpi(user_details.KPI3)+'%' : '-'}}</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.KPI3+'%'" :aria-valuenow="user_details.KPI3" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div v-if="filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024" class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel4}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Вес:</span> {{user_details.VesPokazatel4}} %</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.VesPokazatel4+'%'" :aria-valuenow="user_details.VesPokazatel2" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

                            <!--                            <div><span class="me-1">План:</span> {{formatNumber(user_details.Plan2)}} м²</div>-->
                            <div class="font-size-12 mb-1">План: {{formatNumber(user_details.Plan4)}} EUR \ Факт: {{formatNumber(user_details.Fakt4)}} EUR</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.Fakt4 / user_details.Plan4)*100) + '%'" :aria-valuenow="((user_details.Fakt4 / user_details.Plan4)*100)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

                            <!--                            <div><span class="me-1">Факт:</span> {{formatNumber(user_details.Fakt2)}} м²</div>-->

                            <div class="font-size-12 mb-1"><span class="me-1">KPI #4:</span> {{user_details.KPI4 ? kpi(user_details.KPI4)+'%' : '-'}}</div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.KPI4+'%'" :aria-valuenow="user_details.KPI4" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div v-else class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel4}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Премия, план:</span> <span class="text-warning">{{user_details.VesPokazatel4}} %</span> \ Премия, факт: <span v-bind:class="user_details.VesPokazatel4 > user_details.KPI4 ? 'text-danger' : 'text-success'">{{user_details.KPI4 ? kpi(user_details.KPI4)+'%' : '-'}}</span></div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.KPI4 / user_details.VesPokazatel4) * 100)+'%'" :aria-valuenow="((user_details.KPI4 / user_details.VesPokazatel4)*100)" aria-valuemin="0" aria-valuemax="40"></div>
                            </div>
<!--                            <div class="font-size-12"><span class="me-1">Премия, факт:</span> {{user_details.KPI4 ? kpi(user_details.KPI4)+'%' : '-'}}</div>-->
<!--                            <div class="progress mb-2 bg-danger">-->
<!--                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ user_details.KPI4+'%'" :aria-valuenow="user_details.KPI4" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                            </div>-->
                            <div class="text-success pt-2"><span class="me-1">Заработано:</span> {{formatNumber(user_details.ZarabotanoKPI)}} BYN</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div v-if="filter_mtm.select_month > 1 && filter_mtm.select_year >= 2024" class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel5}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">Премия, план:</span> <span class="text-warning">{{user_details.VesPokazatel5}} %</span> \ Премия, факт: <span v-bind:class="user_details.VesPokazatel5 > user_details.KPI5 ? 'text-danger' : 'text-success'">{{user_details.KPI5 ? kpi(user_details.KPI5)+'%' : '-'}}</span></div>
                            <div class="progress mb-2 bg-danger">
                                <div class="progress-bar progress-bar-striped bg-info" role="progressbar" :style="'width:'+ ((user_details.KPI5 / user_details.VesPokazatel5) * 100)+'%'" :aria-valuenow="((user_details.KPI5 / user_details.VesPokazatel5)*100)" aria-valuemin="0" aria-valuemax="40"></div>
                            </div>
                            <div class="text-success pt-2"><span class="me-1">Заработано:</span> {{formatNumber(user_details.ZarabotanoKPI)}} BYN</div>
                        </div>
                        <div v-else class="flex-grow-1">
                            <h6 class="font-size-12">{{user_details.Pokazatel5}}</h6>
                            <div class="font-size-12 mb-1"><span class="me-1">План:</span> {{formatNumber(user_details.Plan5)}} \ Факт: {{formatNumber(user_details.Fakt5)}}</div>
<!--                            <div class="font-size-12"><span class="me-1">Факт:</span> {{formatNumber(user_details.Fakt5)}}</div>-->
                            <div class="text-success"><span class="me-1">Заработано:</span> {{user_details.ZarabotanoKvBonus}} BYN</div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert-primary text-center" role="alert">
                <b><span class="me-1">Итого:</span> {{formatNumber(user_details.ITOGO)}} BYN</b>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import FilterMTMComponent from "./ui/FilterMTMComponent.vue";
import moment from "moment";

export default {
    name: "KPIComponent",
    components:{FilterMTMComponent},
    data:()=>({
        users:[],
        user_details:[],
        formFilter:{
            filter_period_start:null,
            filter_period_end:null,
        },
        filter_mtm:{
            mtm:true,
            select_month:moment().add(-1, 'month').month(),
            select_year:moment().year(),
        },
    }),
    created() {
        this.formFilter.filter_period_start = this.formatDateDashYMD(moment(this.filter_mtm.select_month+1,'MM').startOf('month'))
        this.formFilter.filter_period_end = this.formatDateDashYMD(moment(this.filter_mtm.select_month+1,'MM').endOf('month'))
        this.search()

    },
    methods: {
        search() {
            this.preloaderShow()
            this.users = []
            axios.post('/analytics/kpi/search',this.formFilter).then(response => {
                if (response.data)
                    this.users = response.data
            }).catch(error => {
            }).finally(() => {
                this.preloaderClose()
            });
        },
        userDetails(user){
            this.scrollTop(".offcanvas-body")
            this.user_details = user
        },
        kpi(x){
          return parseFloat(x.toFixed(1))
        }
    }
}
</script>

<style scoped>
</style>
