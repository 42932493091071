<template>
    <SelectFilterComponent :formFilter="formFilter"
                           :search="search" />

    <div v-show="formFilter.filter_period_start && formFilter.filter_period_end">
        <div v-for="(gs,key) in groupedSales" class="row">
            <h6 class="col-12 m-2">{{gs.Poroda || 'По всем породам'}}</h6>
            <div v-for="os in filterSales(gs.Poroda)" class="col-xxl-3 col-xxl-mac-3 col-xl-6 col-md-6">
                <div v-if="gs.Poroda === os.Poroda" class="card border mb-md-4 mb-3" v-bind:class="os.GroupNomNaim || 'total'">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <div class="lh-1"><small class="text-muted">{{os.Poroda || 'По всем породам'}}</small></div>
                                <div class="text-truncate font-size-14 mb-1 text-primary">{{os.GroupNomNaim || (os.Poroda ? GROUP_ALL : 'ВСЕГО ПО ГРУППАМ')}}</div>
                                <h4 class="mb-1">{{formatNumber(os.KolichestvoFact)}} м²
                                    <span class="font-size-12"
                                          v-bind:class="os.ProcentPrirosta > 0 ? 'text-success'
                                          : os.ProcentPrirosta === 0 ? 'text-warning'
                                          : 'text-danger'">
                                        <i class="mdi font-size-14"
                                           v-bind:class="os.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                           : os.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                           : 'mdi-menu-down'"> </i>{{os.ProcentPrirosta}} %
                                    </span>
                                </h4>
                                <p class="text-muted mb-0">
                                    <span class="fw-bold font-size-12 me-2"
                                          v-bind:class="os.OtklonenieOtPlana > 0 ? 'text-success'
                                          : os.OtklonenieOtPlana === 0 ? 'text-warning'
                                          : 'text-danger'">
                                    <i class="me-1 align-middle"
                                       v-bind:class="os.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                       : os.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                       : 'ri-arrow-right-down-line'"></i>{{os.OtklonenieOtPlana}}%</span>отношение к плану
                                </p>
                            </div>
                            <div v-show="formFilter.filter_category !== 'OrdersBalance'" class="avatar-sm">
                                <a @click="filterDetails(os)" href="" class="avatar-title bg-light text-primary rounded-3"
                                   data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                    <i class="ri-equalizer-line font-size-24"></i>
                                </a>
                            </div>
                        </div>
                    </div><!-- end cardbody -->
                </div><!-- end card -->
            </div>
        </div>
    </div>

    <div v-show="formFilter.filter_period_start && formFilter.filter_period_end" class="row">
        <div v-if="!!ordersSales.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card border">
                <div class="card-body">
                    <h4 class="card-title mb-3">Подробно по группам
                        <div><small class="text-muted">COSWiCK Solid Hardwood Floors.</small></div>
                    </h4>

                    <div class="table-responsive">
                        <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                            <thead class="table-light">
                            <tr>
                                <th>Название группы</th>
                                <th>Кол-во факт.</th>
                                <th>Кол-во пр.</th>
                                <th>Кол-во %</th>
                                <th>План</th>
                                <th>% план</th>
                            </tr>
                            </thead>
                            <tbody v-for="(gs,index) in groupedSales">
                            <tr><td colspan="6">{{index+1}}. {{gs.Poroda || 'По всем породам'}}</td></tr>
                            <tr v-for="os in filterSales(gs.Poroda)">
                                <td>
                                    <h6 class="mb-0">{{os.GroupNomNaim || (gs.Poroda ? GROUP_ALL : 'ВСЕГО ПО ГРУППАМ')}}</h6>
                                </td>
                                <td class="text-info">{{formatNumber(os.KolichestvoFact)}} м²</td>
                                <td>{{formatNumber(os.KolichestvoFactPred)}} м²</td>
                                <td>
                                    <div class="font-size-13"
                                         v-bind:class="os.ProcentPrirosta > 0 ? 'text-success'
                                         : os.ProcentPrirosta === 0 ? 'text-warning'
                                         : 'text-danger'">
                                        <i class="mdi font-size-14 align-middle me-1"
                                           v-bind:class="os.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                           : os.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                           : 'mdi-menu-down'"></i>{{os.ProcentPrirosta}} %
                                    </div>
                                </td>
                                <td>{{formatNumber(os.KolichestvoPlan)}} м²</td>
                                <td>
                                    <div class="font-size-13"
                                         v-bind:class="os.OtklonenieOtPlana > 0 ? 'text-success'
                                         : os.OtklonenieOtPlana === 0 ? 'text-warning' : 'text-danger'">
                                        <i class="font-size-10 align-middle me-1"
                                           v-bind:class="os.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                           : os.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                           : 'ri-arrow-right-down-line'"></i>{{os.OtklonenieOtPlana}} %
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table> <!-- end table -->
                    </div>
                </div>
            </div><!-- end card -->
        </div>

        <div v-if="!!series[0].data.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card border">
                <div class="card-body px-0">
                    <h4 class="card-title ps-4 mb-0">Диаграмма по группам
                        <div><small class="text-muted">COSWiCK Solid Hardwood Floors. ({{formFilter.filter_category}})</small></div>
                    </h4>
                        <apexchart height="850" type="bar" :options="chartOptions" :series="series"/>
                </div>
            </div>
        </div>
    </div>

    <Details :GROUP_ALL="GROUP_ALL"
             :filterOS="filterOS"
             :spinner_details="spinner_details"
             :filterDetailsOrders="filterDetailsOrders"
             :seriesDetails="seriesDetails"
             :chartOptionsDetails="chartOptionsDetails" />

</template>

<script>
import axios from "axios";
import SelectFilterComponent from "../ui/SelectFilterComponent.vue";
import Details from "./Details.vue";

export default {
    name: "PriceManagementComponent",
    components:{SelectFilterComponent, Details},
    data:()=>({
        GROUP_ALL:'ВСЕГО ПО ГРУППЕ',
        lists:[],
        lists_details:[],
        filter:{
            filter_details:null,
            filter_breed:null,
        },

        formFilter: {
            filter_category: 'Orders',
            filter_period: 'WTD',
            filter_period_start: null,
            filter_period_end: null,
        },

        // GROUP
        series: [{
            data: [],
        }],
        chartOptions: {},

        // DETAILS
        seriesDetails: [],
        chartOptionsDetails: {},
        spinner_details:false,

    }),
    created() {
        this.search()
    },
    computed: {
        ordersSales() {
            return this.lists ? this.lists.sort((a, b) => {
                return (a.PorodaSort - b.PorodaSort || 99 ) && (a.GroupNomNaim || "999").localeCompare((b.GroupNomNaim || "999"))
                    }) : []
        },
        groupedSales() {
            return this.lists.filter((lists, index, self) => self.findIndex(t => t.Poroda === lists.Poroda ) === index) //&& t.Poroda !== ''
        },
        filterOS(){
            return this.lists.filter(os => {
                return os.IDGroup === this.filter.filter_details && os.Poroda === this.filter.filter_breed
            })[0]
        },
        filterDetailsOrders(){
            return this.lists_details ? this.lists_details.sort((a, b) => {return a.KomSortSort - b.KomSortSort}).filter(os => {
                return os.IDGroup === this.filter.filter_details && os.Poroda === this.filter.filter_breed
            }) : []
        },
    },
    methods:{
        search(){
            this.preloaderShow()
            this.period(this.formFilter)

            axios.post('/analytics/price-management/search',this.formFilter).then(response => {
                this.lists = response.data['data'] ?? []
                this.diagramSeries()
                this.diagramOptions()
            }).catch(error => {
                // console.log(error);
            }).finally(() => {this.preloaderClose()})
            this.searchDetails()
        },

        diagramSeries(){
            this.series[0] = {
                name: this.formFilter.filter_category === 'Sales' ? 'Продажи , м²' : 'Заказы , м²',
                data: this.ordersSales.filter(element => element.GroupNomNaim !== '').map(sales => sales.KolichestvoFact),
            }
        },

        diagramOptions(){
            this.chartOptions = {
                chart: {
                    // type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        // barHeight: '80%',
                        // distributed: true,
                        horizontal: true,
                        borderRadius: 4,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                // colors: [],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        fontSize: '12px',
                        colors: ['#b0b8bf'],
                        fontWeight: '200',
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + ' м²'
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 0.5,
                    colors: ['#fff']
                },
                xaxis:{
                    categories:this.ordersSales.filter(element => element.GroupNomNaim !== '').map(sales => sales.GroupNomNaim),
                    position: 'bottom',
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                },
                // title: {
                //     text: 'COSWiCK Solid Hardwood Floors.',
                //     align: 'right',
                //     floating: true,
                //     // margin: 20,
                // },
                subtitle: {
                    text: 'COSWiCK Solid Hardwood Floors.',
                    align: 'right',
                    margin: 5,
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        show: false
                    },
                    // y: {
                    //     title: {
                    //         formatter: function () {
                    //             return ''
                    //         }
                    //     }
                    // }
                }
                // colors: this.ordersSales.map(sales => (sales.Elka === true ? '#6fd088' : '#057fde'))
            };
        },

        filterSales(poroda){
            return this.lists.filter(res => res.Poroda === poroda)
        },

        filterDetails(os){
            this.scrollTop(".offcanvas-body")

            this.filter.filter_details = os.IDGroup
            this.filter.filter_breed = os.Poroda

            this.seriesDetails = this.filterDetailsOrders.map(details => details.KolichestvoFact)
            this.chartOptionsDetails = {
                chart: {
                    type: 'donut',
                    foreColor: "#fff",
                },
                labels: this.filterDetailsOrders.map(details => details.KomSortNaim),
                legend: {
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                            },
                        }
                    }
                },

                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + " м²";
                        }
                    },
                },
            }
        },

        async searchDetails(){
            this.spinner_details = true
            this.lists_details = []
            axios.post('/analytics/price-management/search-details',this.formFilter).then(response => {
                this.lists_details = response.data['data'] ?? []
            }).catch(error => {
                // console.log(error);
            }).finally(() => {this.spinner_details = false})
        },
    }
}
</script>

<style scoped>
</style>
