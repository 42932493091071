<template>
    <SelectFilterComponent :formFilter="formFilter"
                           :search="search" />
    <div v-if="!!vpGrouped.length" v-for="group in vpGrouped" class="row">
        <h6 class="col-12 m-2">{{group.ServiceTypeParent || 'По всем категориям'}}</h6>
        <div v-for="filter in vpFilter(group)" class="col-xxl-3 col-xxl-mac-3 col-xl-6 col-md-6">
            <div class="card mb-md-4 mb-3 " v-bind:class="filter.ServiceTypeParent || 'total'">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1 text-primary">
                                <div class="text-uppercase">{{filter.ServiceType || (filter.ServiceTypeParent ? 'ИТОГО: ' + filter.ServiceTypeParent : CATEGORY_ALL) }}</div>
                                <div class="lh-1"><small class="text-muted"></small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(filter.PriceStandart)}} руб.
                                <span class="font-size-12"
                                      v-bind:class="filter.OtkloneniePriceStandart > 0 ? 'text-success'
                              : filter.OtkloneniePriceStandart === 0 ? 'text-warning'
                              : 'text-danger'">
                            <i class="mdi font-size-14"
                               v-bind:class="filter.OtkloneniePriceStandart > 0 ? 'mdi-menu-up'
                               : filter.OtkloneniePriceStandart === 0 ? 'mdi-swap-vertical'
                               : 'mdi-menu-down'"> </i>{{filter.OtkloneniePriceStandart}} %
                        </span>
                            </h4>
                            <p class="text-muted mb-0">
                        <span class="fw-bold font-size-12 me-2 text-info">
                        <i class="me-1 align-middle ri-recycle-line "></i>{{filter.ServiceType ? specificGravity(filter) : 100}}%</span><small>- удельный вес</small>
                            </p>
                        </div>
                        <div class="avatar-sm">
                            <a @click="vpFilterDetails(filter)" href="" class="avatar-title bg-light text-primary rounded-3"
                               data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                <i class="ri-equalizer-line font-size-24"></i>
                            </a>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>
        </div>
    </div>
    <div class="row">
        <div v-if="!!vpGrouped.length" class="col-xxl-7 col-xl-12">
            <div class="card">
                <div class="card-body border">
                    <h4 class="card-title mb-3">Подробно по регионам
                        <div><small class="text-muted">База отдыха Viliya Park</small></div>
                    </h4>

                    <div class="table-responsive">
                        <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                            <thead class="table-light">
                            <tr>
                                <th>Название категории</th>
                                <th>Выручка</th>
                                <th>Выручка пр.</th>
                                <th>Выручка %</th>
                            </tr>
                            </thead><!-- end thead -->
                            <tbody v-for="group in vpGrouped">
                            <tr><td colspan="6" class="text-uppercase">{{group.ServiceTypeParent || 'По всем категориям'}}</td></tr>
                            <tr v-for="x in vpFilter(group)">
                                <td><h6 class="mb-0">{{x.ServiceType || (x.ServiceTypeParent ? 'ИТОГО: ' + x.ServiceTypeParent : CATEGORY_ALL)}}</h6></td>
                                <td class="text-white">{{formatNumber(x.PriceStandart)}} руб.</td>
                                <td>{{formatNumber(x.SumPred)}} руб.</td>
                                <td>
                                    <div class="font-size-13"
                                         v-bind:class="x.OtkloneniePriceStandart > 0 ? 'text-success'
                                         : x.OtkloneniePriceStandart === 0 ? 'text-warning'
                                         : 'text-danger'">
                                        <i class="mdi font-size-14 align-middle me-1"
                                           v-bind:class="x.OtkloneniePriceStandart > 0 ? 'mdi-menu-up text-success'
                                       : x.OtkloneniePriceStandart === 0 ? 'mdi-swap-vertical text-warning'
                                       : 'mdi-menu-down text-danger'"></i>{{x.OtkloneniePriceStandart}} %
                                    </div>
                                </td>
                            </tr>
                            </tbody><!-- end tbody -->
                        </table> <!-- end table -->
                    </div>
                </div><!-- end card -->
            </div><!-- end card -->

            <div v-if="!!plan_list.length">
                <div class="card">
                    <div class="card-body border">
                        <h4 class="card-title mb-3">Проживание ( План / Факт )
                            <div><small class="text-muted">База отдыха Viliya Park</small></div>
                        </h4>

                        <div class="table-responsive">
                            <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                                <thead class="bg-light">
                                <tr>
                                    <th>Дата</th>
                                    <!--                                <th>Кол-во комнат</th>-->
                                    <th><i class="mdi mdi-calendar-multiple"></i> План</th>
                                    <th><i class="mdi mdi-calendar-multiple-check"></i> Факт</th>
                                    <th><i class="mdi mdi-account-multiple"></i> План</th>
                                    <th><i class="mdi mdi-account-multiple-check"></i> Факт </th>
                                    <th>% План / Факт </th>

                                </tr>
                                </thead><!-- end thead -->
                                <tbody v-for="plan in plan_list">
                                <tr>
                                    <td class="text-info fw-bold">{{plan.Period}}</td>
                                    <!--                                <td>{{plan.QuantityRooms}}</td>-->
                                    <td class="text-dark"><i class="mdi mdi-calendar-multiple"></i> {{plan.QuantityPlanReserv}}</td>
                                    <td v-bind:class="(plan.QuantityPlanReserv <= plan.QuantityFactAccomon && percentFactPlan(plan) > 100) ? 'text-success' : percentFactPlan(plan) < 20 || !compareDate(plan.Period) ? 'text-danger' : 'text-warning'">
                                        <i class="mdi mdi-calendar-multiple-check"></i> {{compareDate(plan.Period) ? plan.QuantityFactAccomon : 0}}</td>

                                    <td><i class="mdi mdi-account-multiple"></i> {{plan.QuantityGuestPlan}}</td>
                                    <td><i class="mdi mdi-account-multiple-check"></i> {{compareDate(plan.Period) ? plan.QuantityGuestFact : 0}}</td>
                                    <td>
                                        <label class="font-size-12"
                                               v-bind:class="percentFactPlan(plan) >= 100 ? 'text-success'
                                               : percentFactPlan(plan) < 20 || !compareDate(plan.Period) ? 'text-danger'
                                               : 'text-warning'">{{ compareDate(plan.Period) ? percentFactPlan(plan) : 0 }} %</label>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped" v-bind:class="percentFactPlan(plan) >= 100 ? 'bg-success' : percentFactPlan(plan) < 20 || !compareDate(plan.Period) ? 'bg-danger' : 'bg-warning'" role="progressbar" :style="'width:' +(compareDate(plan.Period) ? percentFactPlan(plan) : 0) + '%'" :aria-valuenow="(compareDate(plan.Period) ? percentFactPlan(plan) : 0)" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- end card -->
                </div><!-- end card -->
            </div>
        </div>

        <div v-if="!!chart.length" class="col-xxl-5 col-xl-12">
            <div class="row">
                <div v-if="$can('7. ОТОБРАЖАТЬ ОТЕЛЬ')" class="col-12">
                    <div class="card border ">
                        <div class="card-body px-0">
                            <h4 class="card-title ps-4 mb-0">Отель
                                <div><small class="text-muted">База отдыха Viliya Park</small></div>
                            </h4>
                            <div class="row">
                                <div class="col-11">
                                    <apexchart type="pie"
                                               height="200"
                                               :options="chartOptionsHotel"
                                               :series="chartHotel.map(vp => vp.PriceStandart)"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$can('7. ОТОБРАЖАТЬ ОБЩЕПИТ')" class="col-12">
                    <div class="card border ">
                        <div class="card-body px-0">
                            <h4 class="card-title ps-4 mb-0">Общепит
                                <div><small class="text-muted">База отдыха Viliya Park</small></div>
                            </h4>
                            <div class="row">
                                <div class="col-11 ">
                                    <apexchart type="pie"
                                               height="200"
                                               :options="chartOptionsPublicCatering"
                                               :series="chartPublicCatering.map(vp => vp.PriceStandart)"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$can('7. ОТОБРАЖАТЬ ПИВОВАРНЯ')" class="col-12">
                    <div class="card border ">
                        <div class="card-body px-0">
                            <h4 class="card-title ps-4 mb-0">Пивоварня
                                <div><small class="text-muted">База отдыха Viliya Park</small></div>
                            </h4>
                            <div class="row">
                                <div class="col-11">
                                    <apexchart type="pie"
                                               height="200"
                                               :options="chartOptionsBrewery"
                                               :series="chartBrewery.map(vp => vp.PriceStandart)"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-12" v-if="!!chart.length
                                                && $can('7. ОТОБРАЖАТЬ ОТЕЛЬ')
                                                && $can('7. ОТОБРАЖАТЬ ОБЩЕПИТ')
                                                && $can('7. ОТОБРАЖАТЬ ПИВОВАРНЯ')">
                    <div class="card border">
                        <div class="card-body px-0">
                            <h4 class="card-title ps-4 mb-0">По всем категориям
                                <div><small class="text-muted">База отдыха Viliya Park</small></div>
                            </h4>
                            <div class="d-flex justify-content-center">
                                <div class="col-xxl-10 col-sm-6 ">
                                    <apexchart type="donut"
                                               :options="chartOptionsDetails"
                                               :series="chart"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel" class="my-1">Подробная информация
                <div style="line-height: 0.7"><small class="text-muted font-size-12">База отдыха Viliya Park</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div v-if="filter_category_viliya_park" class="offcanvas-body">
            <div class="card bg-light">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1 text-primary">
                                <div>{{filter_category_viliya_park.ServiceType || (filter_category_viliya_park.ServiceTypeParent ? 'ИТОГО: ' + filter_category_viliya_park.ServiceTypeParent : CATEGORY_ALL)}}</div>
                                <div class="lh-1"><small class="text-muted"></small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(filter_category_viliya_park.PriceStandart)}} руб.
                                <span class="font-size-12"
                                      v-bind:class="filter_category_viliya_park.OtkloneniePriceStandart > 0 ? 'text-success'
                                      : filter_category_viliya_park.OtkloneniePriceStandart === 0 ? 'text-warning'
                                      : 'text-danger'">
                                    <i class="mdi font-size-14"
                                       v-bind:class="filter_category_viliya_park.OtkloneniePriceStandart > 0 ? 'mdi-menu-up'
                                       : filter_category_viliya_park.OtkloneniePriceStandart === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{filter_category_viliya_park.OtkloneniePriceStandart}} %
                                </span>
                            </h4>
                            <p class="text-muted mb-0">
                                <span class="fw-bold font-size-12 me-2 text-info">
                                <i class="me-1 align-middle ri-recycle-line "></i>{{filter_category_viliya_park.ServiceType ? specificGravity(filter_category_viliya_park) : 100}}%</span><small>- удельный вес</small>
                            </p>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>

            <div v-if="vpDetailsDohod" class="card card-body bg-light">
                <h4 class="card-title text-primary">ВЫРУЧКА</h4>
                <div v-for="vp in vpDetailsDohod" class="flex-1">
                    <h6 class="mb-1">{{vp.Service}}</h6>
                    <div class="font-size-12 text-muted">
                        <p v-if="vp.ServiceType === 'Столовая' || vp.ServiceType ==='Проживание'" class="mb-0">
                            <i class="mdi mdi-credit-card-check"></i> Выручка: {{formatNumber(vp.Sum)}} руб.
                            <span class="font-size-11" v-bind:class="vp.OtkloneniePriceStandart > 0 ? 'text-success'
                            : vp.OtkloneniePriceStandart === 0 ? 'text-warning'
                            : 'text-danger'">({{vp.OtklonenieSum}} %)</span>
                        </p>
                        <p v-else class="mb-0">
                            <i class="mdi mdi-credit-card-check"></i>
                            Выручка: {{formatNumber(vp.PriceStandart)}} руб.
                            <span class="font-size-11"
                                  v-bind:class="vp.OtkloneniePriceStandart > 0 ? 'text-success'
                                  : vp.OtkloneniePriceStandart === 0 ? 'text-warning'
                                  : 'text-danger'">({{vp.OtkloneniePriceStandart}} %)</span>
                        </p>

                        <p v-if="vp.ServiceType === 'Столовая' || vp.ServiceType ==='Проживание'" class="mb-0">
                            <i class="mdi mdi-credit-card-refresh-outline"></i> Компенсация: {{vp.Kompensation}} руб.</p>
                        <p v-if="vp.Zagr>0" class="mb-0"><i class="mdi mdi-account-group"></i> Загрузка: {{vp.Zagr}} %</p>
                        <p v-if="vp.ServiceTypeParent === 'Отель' && vp.ServiceType ==='Проживание'" class="mb-0"><i class="fa fa-user"></i> Среднее кол-во гостей: {{vp.SredClients}}</p>
                        <p v-if="vp.PriceStandart && vp.Service !== 'Банкет' && vp.ServiceTypeParent !=='Прочее'" class="mb-0">
                            <i class="mdi mdi-credit-card-clock"></i>
                            <span v-if="vp.ServiceTypeParent === 'Отель' && vp.ServiceType !=='Проживание'"> Средняя цена</span>
                            <span v-else-if="vp.ServiceTypeParent === 'Отель' && vp.ServiceType ==='Проживание'"> Средняя цена на человека</span>
                            <span v-else> Средний чек</span>: {{ vp.SredCena }} руб.
                        </p>
                        <p v-if="vp.ServiceTypeParent === 'Отель' && vp.ServiceType ==='Проживание'">
                            <i class="mdi mdi-credit-card-clock"></i> Средняя цена на номер: {{formatNumber((vp.PriceStandart/vp.QuantityRoom).toFixed(2))}} руб.</p>

                    </div>
                    <hr class="my-2">
                </div>
            </div>

            <div v-if="!filter_viliya_park" class="card card-body bg-light">
                <h4 class="card-title text-primary">РАСХОДЫ</h4>
                <div v-for="vp in vpDetailsRashod" class="flex-1">
                    <h6 class="mb-1" v-bind:class="!vp.Pokazatel ? 'text-warning' : ''">{{vp.Pokazatel || 'Итого'}}</h6>
                    <div class="font-size-12 text-muted">
                        <p class="mb-1"><i class="mdi mdi-credit-card-minus-outline"></i> {{formatNumber(vp.Sum)}} руб.
                            <span v-bind:class="vp.OtklonenieSum > 0 ? 'text-success'
                            : vp.OtklonenieSum === 0 ? 'text-warning'
                            : 'text-danger'">({{vp.OtklonenieSum}} %)</span></p>
                    </div>
                    <hr class="my-2">
                </div>
            </div>
<!--            <div v-if="!filter_viliya_park" class="card card-body bg-light">-->
<!--                <h4 class="card-title text-primary">ПРИБЫЛЬ</h4>-->
                <!--                <div v-for="vp in vpDetails" class="flex-1">-->
                <!--                    <h6 class="mb-1">{{vp.Service}}</h6>-->
                <!--                    <div class="font-size-12 text-muted">-->
                <!--                        <p class="mb-1"><i class="mdi mdi-credit-card-check"></i> {{formatNumber(vp.PriceStandart)}} руб. / Кол-во: {{formatNumber(vp.Quantity)}}</p>-->
                <!--                    </div>-->
                <!--                    <hr class="my-2">-->
                <!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SelectFilterComponent from "./ui/SelectFilterComponent.vue";

export default {
    name: "ViliyaParkComponent",
    components:{SelectFilterComponent},
    data:()=>({
        CATEGORY_ALL:'ВСЕГО ПО КАТЕГОРИЯМ',
        viliya_park_dohod:[],
        viliya_park_rashod:[],
        filter_category_viliya_park:[],
        filter_viliya_park:null,
        filter_group_viliya_park:null,
        formFilter:{
            // filter_category:'Sales',
            filter_period:'WTD',
            filter_period_start:null,
            filter_period_end:null,
        },

        plan_list:[],

        chartOptionsDetails: {},
    }),
    created() {
        this.search()
    },
    computed: {
        vpGrouped() {
            return !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                .filter((lists, index, self) => self.findIndex(t => t.ServiceTypeParent === lists.ServiceTypeParent) === index)
                .sort((a, b) => (a.ServiceTypeParent || "я").localeCompare(b.ServiceTypeParent || "я"))
                .sort((a, b) => (a.ServiceTypeParentSortCode - b.ServiceTypeParentSortCode)) : []
            // .map(vp => vp.ServiceTypeParent1)
        },
        vpDetailsDohod(){
            return !!this.viliya_park_dohod.length ? this.viliya_park_dohod.filter(vp => (this.filter_viliya_park && this.filter_group_viliya_park
                ? (vp.IDServiceType === this.filter_viliya_park
                    && vp.IDServiceTypeParent === this.filter_group_viliya_park
                    && vp.Service !=='')
                : !this.filter_viliya_park && this.filter_group_viliya_park
                ? (vp.Service !=='' && vp.IDServiceTypeParent === this.filter_group_viliya_park)
            : vp.Service !=='') && vp.PriceStandart !== 0)
                .sort((a, b) => (a.Service || "я").localeCompare(b.Service || "я")) : []
        },
        vpDetailsRashod(){
            return this.viliya_park_rashod.length ? this.viliya_park_rashod.filter(vp =>(this.filter_group_viliya_park
                ? vp.IDServiceTypeParent === this.filter_group_viliya_park
                : vp.IDServiceTypeParent === '')).sort((a,b) => (a.Pokazatel || 'я').localeCompare(b.Pokazatel || 'я')) : [] /// Доделать
        },
        chart(){
            return !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                .filter(vp => vp.Service === '' && vp.ServiceType === '' && vp.ServiceTypeParent).map(vp => vp.PriceStandart) : []
        },
        chartHotel(){
             return !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                        .filter(x => x.ServiceTypeParent === 'Отель' && x.Service === '' && x.PriceStandart !== 0 && x.ServiceType !== '')
                        .sort((a, b) => (a.ServiceType || "я").localeCompare(b.ServiceType || "я"))
                        .sort((a, b) => (a.ServiceTypeSortCode - b.ServiceTypeSortCode))
                        : []
        },

        chartOptionsHotel() {
            return {
                chart: {
                    type: 'pie',
                    height: 200,
                },
                labels: this.chartHotel.map(vp => vp.ServiceType + ' - ' + this.specificGravity(vp) + '%'),
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [val.toFixed(2) + '%']
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + " руб.";
                        }
                    },
                },
            }
        },

        chartPublicCatering(){
            return !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                        .filter(x => x.ServiceTypeParent === 'Общепит' && x.Service === '' && x.PriceStandart !== 0 && x.ServiceType !== '')
                        .sort((a, b) => (a.ServiceType || "я").localeCompare(b.ServiceType || "я"))
                        .sort((a, b) => (a.ServiceTypeSortCode - b.ServiceTypeSortCode))
                        : []
        },

        chartOptionsPublicCatering() {
            return {
                chart: {
                    type: 'pie',
                    height: 200,
                },
                labels: this.chartPublicCatering.map(vp => vp.ServiceType + ' - ' + this.specificGravity(vp) + '%'),
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [val.toFixed(2) + '%']
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + " руб.";
                        }
                    },
                },
            }
        },

        chartBrewery(){
            return !!this.viliya_park_dohod.length ?
                    this.viliya_park_dohod
                        .filter(x => x.ServiceTypeParent === 'Пивоварня' && x.Service === '' && x.PriceStandart !== 0 && x.ServiceType !== '')
                        .sort((a, b) => (a.ServiceType || "я").localeCompare(b.ServiceType || "я"))
                        .sort((a, b) => (a.ServiceTypeSortCode - b.ServiceTypeSortCode))
                        : []
        },
        chartOptionsBrewery() {
            return {
                chart: {
                    type: 'pie',
                    height: 200,
                },
                labels: this.chartBrewery.map(vp => vp.ServiceType + ' - ' + this.specificGravity(vp) + '%'),
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [val.toFixed(2) + '%']
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + " руб.";
                        }
                    },
                },
            }
        }
    },
    methods:{
        search() {
            this.period(this.formFilter)
            this.preloaderShow()
            axios.post('/analytics/viliya-park/search',this.formFilter).then(response => {
                this.viliya_park_dohod = response.data['service']['Dohod']
                this.viliya_park_rashod = response.data['service']['Rashod']
                this.plan_list = response.data['plan']['data']
            }).catch(error => {
            }).finally(() => {
                this.preloaderClose()
                this.diagramOptions()
            });
        },

        vpFilter(group){
            return !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                .filter(res => res.IDServiceTypeParent === group.IDServiceTypeParent && res.Service === '' && res.PriceStandart !== 0)
                .sort((a, b) => (a.ServiceType || "я").localeCompare(b.ServiceType || "я"))
                .sort((a, b) => (a.ServiceTypeSortCode - b.ServiceTypeSortCode)) : []
        },

        vpFilterDetails(val){
            this.scrollTop(".offcanvas-body")
            this.filter_category_viliya_park = val
            this.filter_viliya_park = val.IDServiceType
            this.filter_group_viliya_park = val.IDServiceTypeParent
        },
        diagramOptions(){
            // console.log(this.viliya_park_dohod
            //     .filter(vp => vp.Service === '' && vp.ServiceType === '' && vp.ServiceTypeParent))
            this.chartOptionsDetails = {
                chart: {
                    type: 'donut',
                    foreColor: "#fff",
                },
                labels: !!this.viliya_park_dohod.length ? this.viliya_park_dohod
                    .filter(vp => vp.Service === '' && vp.ServiceType === '' && vp.ServiceTypeParent)
                    .map(vp => vp.ServiceTypeParent + ' - ' + this.specificGravityAll(vp) + '%') : [],
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [val.toFixed(2) + '%']
                    }
                },
                legend: {
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                            },
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + " руб.";
                        }
                    },
                },

            };
        },

        specificGravity(vp){
            let result = ((vp.PriceStandart / this.viliya_park_dohod.filter(filter =>
                                            (vp.IDServiceTypeParent && vp.IDServiceType)
                                                ? filter.ServiceType === '' && filter.IDServiceTypeParent === vp.IDServiceTypeParent
                                                : filter.ServiceType === '')
                                            .map(vp => vp.PriceStandart)[0])*100)
                                            .toFixed(2)
                                            .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1')
            return (isNaN(result)) ? 0 : result
        },

        specificGravityAll(vp){
            let result = ((vp.PriceStandart / this.viliya_park_dohod.filter(filter => filter.ServiceTypeParent === '')
                .map(vp => vp.PriceStandart)[0])*100)
                .toFixed(2)
                .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1')

            return (isNaN(result)) ? 0 : result
        },

        percentFactPlan(plan){
            return parseFloat(((plan.QuantityFactAccomon / plan.QuantityPlanReserv) * 100).toFixed(2))
        },
        compareDate(date){
            return new Date() > new Date(date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'))
        }
    },
}
</script>

<style scoped>
</style>
