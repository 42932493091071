<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasDetail" aria-labelledby="offcanvasDetailLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasDetailLabel" class="my-1">Подробная информация
                <div style="line-height: 0.7"><small class="text-muted font-size-12">Coswick Solid Hardwood Floors</small></div>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div v-if="filter_counterparty" class="text-center">
            <a @click="filter_region_sign=true" data-bs-toggle="offcanvas" href="#offcanvasCounterparties" aria-controls="offcanvasCounterparties">
                <i class="fas fas fa-undo-alt font-size-14 text-dark"> Вернуться к списку контрагентов</i> </a>
        </div>
        <div v-if="filterSales" class="offcanvas-body">
            <div class="card bg-light">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1 text-primary">
                                {{filter_region_sign ? (filterSales.Region || REGION_ALL) : filter_counterparty ? filterSales.Kontragent : null}}
                                <div v-if="filterSales.TipCen" class="lh-1"><small class="text-muted">{{filterSales.TipCen}}</small></div>
                                <div v-else class="lh-1"><small class="text-muted">{{specificGravity(filterSales)}}% - Удельный вес</small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(filterSales.KolichestvoFact)}} м²
                                <span class="font-size-12" v-bind:class="deviationColor(filterSales.ProcentPrirosta)">
                                    <i class="mdi font-size-14"
                                       v-bind:class="filterSales.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                       : filterSales.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{filterSales.ProcentPrirosta}} %
                                </span>
                            </h4>
                            <p class="text-muted mb-0">
                                <span class="fw-bold font-size-12 me-1" v-bind:class="deviationColor(filterSales.OtklonenieOtPlana)">
                                <i class="me-1 align-middle"
                                   v-bind:class="filterSales.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                   : filterSales.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                   : 'ri-arrow-right-down-line'"></i>{{filterSales.OtklonenieOtPlana}}%</span>
                                <span class="font-size-12">- план {{filterSales.KolichestvoPlan}} м²</span>
                            </p>

                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div>

            <div v-if="filterSalesDetailsOrders" class="card card-body bg-light">
                <h4 class="card-title">ORDERS</h4>
                <p class="card-text mb-0">Объем: {{formatNumber(filterSalesDetailsOrders.KolichestvoFact)}} м²
                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsOrders.Otklonenie1)">
                        ({{filterSalesDetailsOrders.Otklonenie1}} %)</span>
                </p>
                <p class="card-text mb-0">Сумма: € {{formatNumber(filterSalesDetailsOrders.StoimostFact)}}
                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsOrders.Otklonenie2)">
                        ({{filterSalesDetailsOrders.Otklonenie2}} %)</span>
                </p>
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ ДОХОД')" class="card-text mb-0">Доход: € {{formatNumber(filterSalesDetailsOrders.DohodFactSum)}}-->
<!--                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsOrders.Otklonenie3)">-->
<!--                            ({{filterSalesDetailsOrders.Otklonenie3}} %)</span>&ndash;&gt;-->
<!--                </p>-->
                <hr>
                <p class="card-text mb-0">Ср. цена: € {{filterSalesDetailsOrders.PriceFact}}
                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsOrders.Otklonenie4)">
                        ({{filterSalesDetailsOrders.Otklonenie4}} %)</span>
                </p>
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ TP')" class="card-text mb-0">TP/м²: € {{filterSalesDetailsOrders.PPZFactM2}}-->
<!--                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsOrders.Otklonenie5)">-->
<!--                            ({{filterSalesDetailsOrders.Otklonenie5}} %)</span>&ndash;&gt;-->
<!--                </p>-->
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ ДОХОД')" class="card-text mb-0">Доход/м²: € {{filterSalesDetailsOrders.DohodFactM2}}-->
<!--                    <span class="font-size-12"-->
<!--                          v-bind:class="otklonenie6(filterSalesDetailsOrders) > 0 ? 'text-success'-->
<!--                          : otklonenie6(filterSalesDetailsOrders) === 0 ? 'text-warning'-->
<!--                          : 'text-danger'">({{otklonenie6(filterSalesDetailsOrders)}} %)</span>-->
<!--                </p>-->
                <!--                <a href="#" class="btn btn-primary waves-effect waves-light">&#45;&#45;&#45;&#45;&#45;&#45;</a>-->
            </div>
            <div v-if="filterSalesDetailsSales" class="card card-body bg-light">
                <h4 class="card-title">SALES</h4>
                <p class="card-text mb-0">Объем: {{formatNumber(filterSalesDetailsSales.KolichestvoFact)}} м²
                    <span class="font-size-12"
                          v-bind:class="deviationColor(filterSalesDetailsSales.Otklonenie1)">({{filterSalesDetailsSales.Otklonenie1}} %)</span>
                </p>
                <p class="card-text mb-0">Сумма: € {{formatNumber(filterSalesDetailsSales.StoimostFact)}}
                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsSales.Otklonenie2)">
                        ({{filterSalesDetailsSales.Otklonenie2}} %)</span>
                </p>
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ ДОХОД')" class="card-text mb-0">Доход/м²: € {{formatNumber(filterSalesDetailsSales.DohodFactSum)}}-->
<!--                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsSales.Otklonenie3)">-->
<!--                            ({{filterSalesDetailsSales.Otklonenie3}} %)</span>&ndash;&gt;-->
<!--                </p>-->
                <hr>
                <p class="card-text mb-0">Ср. цена: € {{filterSalesDetailsSales.PriceFact}}
                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsSales.Otklonenie4)">
                        ({{filterSalesDetailsSales.Otklonenie4}} %)</span>
                </p>
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ TP')" class="card-text mb-0">TP/м²: € {{filterSalesDetailsSales.PPZFactM2}}-->
<!--                    <span class="font-size-12" v-bind:class="deviationColor(filterSalesDetailsSales.Otklonenie5)">-->
<!--                        ({{filterSalesDetailsSales.Otklonenie5}} %)</span>&ndash;&gt;-->
<!--                </p>-->
<!--                <p v-if="$can('2. ОТОБРАЖАТЬ ДОХОД')" class="card-text mb-0">Доход/м²: € {{filterSalesDetailsSales.DohodFactM2}}-->
<!--                    <span class="font-size-12"-->
<!--                          v-bind:class="otklonenie6(filterSalesDetailsSales) > 0 ? 'text-success'-->
<!--                          : otklonenie6(filterSalesDetailsSales) === 0 ? 'text-warning'-->
<!--                          : 'text-danger'">({{otklonenie6(filterSalesDetailsSales)}} %)</span>-->
<!--                </p>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Details",
    props:['REGION_ALL',
        'filterSales',
        'filter_region_sign',
        'filter_counterparty',
        'filterSalesDetailsOrders',
        'filterSalesDetailsSales',
        'specificGravity',
        'deviationColor'],
    methods:{
        otklonenie6(val){
            return (val.DohodPlanM2 > 0) ? (val.DohodFactM2/val.DohodPlanM2*100-100).toFixed(1) : 0
        },


    }
}
</script>

<style scoped>

</style>
