<template>
    <div class="modal fade create-permission-component" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Создать разрешение</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="mb-3">
                        <label class="form-label">Название раздела:</label>
                        <select v-model="form.section" class="form-control form-control-modern" name="customerBillingCountryRegion">
                            <option v-for="section in sections" :value="section.id">{{section.name}}</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Название группы:</label>
                        <select v-model="form.group" class="form-control form-control-modern" name="customerBillingCountryRegion">
                            <option v-for="group in groups" :value="group.id">{{group.name}}</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Название разрешения:</label>
                        <input v-model="form.name" type="text" class="form-control form-control-modern" name="productName" required />
                    </div>

                </div>
                <div class="modal-footer">
                    <button @click="settings(ROLE_USERS)" type="button" class="btn btn-light waves-effect" data-bs-dismiss="modal">Отмена</button>
                    <button @click="savePermission" type="button" class="btn btn-primary waves-effect waves-light" data-bs-dismiss="modal">Сохранить</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "CreatePermissionComponent",
    props:['ROLE_USERS','settings','sections','groups'],
    data:()=>({
        form:{
            section:null,
            group:null,
            name:null,
        },
    }),
    methods:{
        savePermission(){
            axios.post('/administration/settings/create-permission',this.form).then((response)=> {
                console.log(true);
                Object.assign(this.$data.form,this.$options.data().form)
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>

</style>
