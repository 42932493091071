<template>
    <div class="row">
        <div class="col-sm-6 px-0 px-sm-2" >
            <div class="card px-0 px-sm-2 text-card border">
                <div class="card-body">
                    <div class="row">
                        <h4 class="card-title text-uppercase mt-2 mb-4 px-2">
                            {{BA}}
                        </h4>
                        <div v-for="sm in sm_list_sum_bus" class="col-sm-12 col-xxl-6 px-1 px-sm-2">
                            <div class="card">
                                <div class="card-body border">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">СЧЕТ: {{sm.Currency}}</p>
                                            <h4 class="mb-2 fw-bold">
                                                <span class="text-muted">{{sm.Currency}}:</span> {{formatNumber(sm.ResourceClosingBalance)}}
                                            </h4>
                                        </div>
                                        <div class="avatar-sm">
                                            <h4 class="avatar-title bg-light rounded-3 text-dark font-size-16">
                                                {{currency(sm.Currency)}}
                                            </h4>
                                        </div>
                                    </div>
                                </div><!-- end cardbody -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 px-0 px-sm-2">
            <div class="card px-0 px-sm-2 text-card border">
                <div class="card-body">
                    <div class="row">
                        <h4 class="card-title text-uppercase mt-2 mb-4 px-2">
                            {{PA}}
                        </h4>
                        <div v-for="sm in sm_list_sum_per" class="col-sm-12 col-xxl-6 px-1 px-sm-2">
                            <div class="card">
                                <div class="card-body border">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">СЧЕТ: {{sm.Currency}}</p>
                                            <h4 class="mb-2 fw-bold">
                                                <span class="text-muted">{{sm.Currency}}:</span> {{formatNumber(sm.ResourceClosingBalance)}}
                                            </h4>
                                        </div>
                                        <div class="avatar-sm">
                                            <h4 class="avatar-title bg-light rounded-3 text-dark font-size-16">
                                                {{currency(sm.Currency)}}
                                            </h4>
                                        </div>
                                    </div>
                                </div><!-- end cardbody -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import axios from "axios";
const BA = 'Business account'
const PA = 'Personal account'

export default {
    name: "SMComponent",
    data:()=>({
        // type: [],
        BA: BA,
        PA: PA,
        sm_list: [],
        sm_list_sum_bus: [],
        sm_list_sum_per: [],
    }),
    mounted() {
        this.search()
    },
    methods: {
        search() {
            axios.post('/analytics/funds/search-sm').then(response => {
                this.sm_list = response.data['data']

                // this.type = response.data['data'].filter((lists, index, self) =>
                //     self.findIndex(t => t.TypeAccount === lists.TypeAccount && lists.TypeAccount) === index).map(x => x.TypeAccount)

                this.sm_list_sum_bus = this.sumCurrencyAccount(BA);
                this.sm_list_sum_per = this.sumCurrencyAccount(PA);
            }).finally(() => {
                this.preloaderClose()
            })
        },

        sumCurrencyAccount(type){
            return Array.from(this.sm_list.filter(x => x.TypeAccount === type)
                    .reduce((m, {Currency, ResourceClosingBalance}) =>
                        m.set(Currency, (m.get(Currency) || 0) + ResourceClosingBalance), new Map),
                ([Currency, ResourceClosingBalance]) => ({Currency, ResourceClosingBalance}));
        }
    }
}
</script>

<style scoped>
@media (min-width: 1200px) and (max-width: 1700px) {
    .col-xxl-6{
        width: 100% !important;
    }
}
</style>
