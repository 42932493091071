<template>
    <div class="table-responsive" style="background: rgb(56, 63, 79)">
        <table class="table table-bordered border-secondary table-hover mb-0">
            <thead>
            <tr class="text-white">
                <th rowspan="2">Фонд</th>
                <th class="text-center" rowspan="2">Источник пополнения</th>
                <th colspan="4" class="text-center">{{form.year}} нарастающим итогом</th>
                <th v-for="list in list_month" rowspan="2">{{list.ru}}</th>
            </tr>
            <tr class="text-white">
                <th>начало</th>
                <th>поступило</th>
                <th>потрачено</th>
                <th>конец</th>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr v-for="fund in funds">
                <th v-if="fund" class="py-2 text-start" v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info' : fund.id_found === 5 ? 'ps-4' : fund.id_found === 6 ? 'ps-5' : 'text-white'">{{fund.name}}</th>
                <td v-if="fund"  class="py-2">{{fund.percent ? fund.percent + '% от ЧП' : ''}}</td>
                <td class="py-2 text-white">{{Math.round(fund.start_total) || ''}}</td>
                <td v-bind:class="(fund.id_found === 1 || fund.id_found === 2) ? 'text-info' : 'text-success'" class="py-2">{{fund.id === 1 || fund.id === 2 ? formatNumber(Math.round(sumFundMonth(fund))) : (sumFundReceived(fund) ? formatNumber(Math.round(sumFundReceived(fund))) : '')}}</td>
                <td class="py-2 text-danger">{{fund.id_found !== 1 && fund.id_found !== 2 ? (sumFundMonth(fund) ? formatNumber(Math.round(sumFundMonth(fund))) : '') : ''}}</td>
                <td class="py-2 text-white">{{fund.id_found !== 1 && fund.id_found !== 2 ? formatNumber(Math.round(fund.start_total + sumFundReceived(fund) - sumFundMonth(fund))) : ''}}</td>
                <td v-if="fund" v-for="month in list_month" v-bind:class="statusFundClass(fund.id_found)" class="py-2">{{fund[month.en] ? valueRound(fund[month.en]) : ''}}</td>
            </tr>
            <tr class="text-warning">
                <th class="text-start">ИТОГО</th>
                <th></th>
                <th>{{formatNumber(total.start_all)}}</th>
                <th>{{formatNumber(total.received_all)}}</th>
                <th>{{formatNumber(total.spent_all)}}</th>
                <th>{{formatNumber(total.total_all)}}</th>
                <th v-for="month in list_month">{{totalMonth(month.en)}}</th>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
export default {
    name: "Fund2023",
    props:['form','funds','list_month','total'],
    methods:{
        totalMonth(month){
            let result = Math.round(this.funds.filter(x => x && x.visibility === '1')
                .reduce((partialSum, a) => partialSum + a[month], 0))
            return result ? this.formatNumber(result) : '';
        },

        sumFundReceived(fund){
            return (fund.id === 3 ? this.funds.filter(x => x.id === 2).map(x => this.sumFundMonth(x))[0] :
                    (fund.percent ? (this.funds.filter(x => x.id === 1).map(x => this.sumFundMonth(x))[0] / 100 * fund.percent)
                        : (this.funds.filter(x => x.id === 1).map(x => this.sumFundMonth(x))[0] / 100 * this.funds.filter(x => x.id === fund.id_group).map(x=>x.percent) / 2))
            )
        },
        sumFundMonth(fund){
            if(fund){
                return fund.january + fund.february + fund.march + fund.april + fund.may + fund.june +
                    fund.july + fund.august + fund.september + fund.october + fund.november + fund.december
            }
        },

        statusFundClass(fund){
            return (fund === 1 || fund === 2) ? 'text-info' : ''
        },

        valueRound(val){
            return (Math.sign(val) === -1 ? (- Math.round(val * -1)) : Math.round(val))
        }
    }
}
</script>

<style scoped>

</style>
