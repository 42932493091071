<template>
    <SelectFilterComponent :formFilter="formFilter"
                           :search="search" />

    <div v-show="formFilter.filter_period_start && formFilter.filter_period_end" class="row">
        <div v-if="!!ordersSales.length" v-for="os in ordersSales" class="col-xxl-3 col-xxl-mac-3 col-xl-6 col-md-6">
            <div class="card mb-md-4 mb-3 " v-bind:class="os.Region || 'total'">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <div class="text-truncate font-size-14 mb-1 text-primary">
                                <a @click="filterDetailsSales(os)" data-bs-toggle="offcanvas"
                                   href="#offcanvasCounterparties" aria-controls="offcanvasCounterparties">{{os.Region || REGION_ALL}}</a>
                                <div class="lh-1"><small class="text-muted">{{specificGravity(os)}}% - удельный вес</small></div>
                            </div>
                            <h4 class="mb-0">{{formatNumber(os.KolichestvoFact)}} м²
                                <span class="font-size-12" v-bind:class="deviationColor(os.ProcentPrirosta)">
                                    <i class="mdi font-size-14"
                                       v-bind:class="os.ProcentPrirosta > 0 ? 'mdi-menu-up'
                                       : os.ProcentPrirosta === 0 ? 'mdi-swap-vertical'
                                       : 'mdi-menu-down'"> </i>{{os.ProcentPrirosta}} %
                                </span>
                            </h4>
                            <p class="text-muted mb-0">
                                <span class="fw-bold font-size-12 me-1" v-bind:class="deviationColor(os.OtklonenieOtPlana)">
                                <i class="me-1 align-middle"
                                   v-bind:class="os.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line'
                                   : os.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill'
                                   : 'ri-arrow-right-down-line'"></i>{{os.OtklonenieOtPlana}}%</span>
                                <span class="font-size-12">- план {{os.KolichestvoPlan}} м²</span>
                            </p>
                        </div>
                        <div v-show="formFilter.filter_category !== 'OrdersBalance'" class="avatar-sm">
                            <a @click="filterDetailsSales(os)" href="" class="avatar-title bg-light text-primary rounded-3"
                               data-bs-toggle="offcanvas" data-bs-target="#offcanvasDetail" aria-controls="offcanvasDetail">
                                <i class="ri-equalizer-line font-size-24"></i>
                            </a>
                        </div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div>

    <div v-show="formFilter.filter_period_start && formFilter.filter_period_end" class="row">
        <div v-if="!!ordersSales.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card">
                <div class="card-body border">
                    <h4 class="card-title mb-3">Подробно по регионам
                        <div><small class="text-muted">Coswick Solid Hardwood Floors.</small></div>
                    </h4>

                    <div class="table-responsive">
                        <table class="table table-centered mb-0 align-middle table-hover table-nowrap">
                            <thead class="table-light">
                            <tr>
                                <th>Название региона</th>
                                <th>Кол-во факт.</th>
                                <th>Кол-во пр.</th>
                                <th>Кол-во %</th>
                                <th>План</th>
                                <th>% план</th>
                            </tr>
                            </thead><!-- end thead -->
                            <tbody>
                            <tr v-for="os in ordersSales">
                                <td><h6 class="mb-0">{{os.Region || REGION_ALL}}</h6></td>
                                <td class="text-info">{{formatNumber(os.KolichestvoFact)}} м²</td>
                                <td>{{formatNumber(os.KolichestvoFactPred)}} м²</td>
                                <td>
                                    <div class="font-size-13" v-bind:class="deviationColor(os.ProcentPrirosta)">
                                        <i class="mdi font-size-14 align-middle me-1"
                                           v-bind:class="os.ProcentPrirosta > 0 ? 'mdi-menu-up text-success'
                                       : os.ProcentPrirosta === 0 ? 'mdi-swap-vertical text-warning'
                                       : 'mdi-menu-down text-danger'"></i>{{os.ProcentPrirosta}} %
                                    </div>
                                </td>
                                <td>{{formatNumber(os.KolichestvoPlan)}} м²</td>
                                <td>
                                    <div class="font-size-13" v-bind:class="deviationColor(os.OtklonenieOtPlana)">
                                        <i class="font-size-10 align-middle me-1"
                                           v-bind:class="os.OtklonenieOtPlana > 0 ? 'ri-arrow-right-up-line text-success'
                                           : os.OtklonenieOtPlana === 0 ? 'ri-arrow-up-down-fill text-warning'
                                           : 'ri-arrow-right-down-line text-danger'"></i>{{os.OtklonenieOtPlana}} %
                                    </div>
                                </td>
                            </tr>
                            </tbody><!-- end tbody -->
                        </table> <!-- end table -->
                    </div>
                </div><!-- end card -->
            </div><!-- end card -->
        </div>

        <div v-if="!!seriesCoswick[0].data.length" class="col-xxl-6 col-xxl-mac-6 col-xl-12">
            <div class="card">
                <div class="card-body border">
                    <h4 class="card-title mb-0">Диаграмма по регионам
                        <div><small class="text-muted">Coswick Solid Hardwood Floors.</small></div>
                    </h4>
                    <div v-if="!!seriesCoswick[0].data.length && chartOptionsCoswick">
                        <apexchart height="420" :options="chartOptionsCoswick" :series="seriesCoswick"></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Details
        :REGION_ALL="REGION_ALL"
        :filterSales="filterSales"
        :filter_region_sign="filter_region_sign"
        :filter_counterparty="filter_counterparty"
        :filterSalesDetailsOrders="filterSalesDetailsOrders"
        :filterSalesDetailsSales="filterSalesDetailsSales"
        :specificGravity="specificGravity"
        :deviationColor="deviationColor" />

    <Counterparties
        :REGION_ALL="REGION_ALL"
        :filterSales="filterSales"
        :filterCounterpartySales="filterCounterpartySales"
        :formFilter="formFilter"
        @counterpartySales="counterpartySales"
        :specificGravity="specificGravity" />
</template>

<script>
import axios from "axios";
import SelectFilterComponent from "../ui/SelectFilterComponent.vue";
import Counterparties from "./Counterparties.vue";
import Details from "./Details.vue";

const SALES = 'Sales'
const ORDERS = 'Orders'

export default {
    name: "SalesFactComponent",
    components:{SelectFilterComponent, Counterparties, Details},
    data:()=>({
        REGION_ALL:'ВСЕГО ПО РЕГИОНАМ',
        SALES:SALES,
        ORDERS:ORDERS,
        sales_list:[],
        sales_list_details:[], // Детали
        sales_list_counterparty:[], // Список контрагентов
        sales_list_counterparty_details:[],

        filter_region_sign:false,
        filter_region:null,
        filter_counterparty:null,

        formFilter:{
            filter_category:SALES,//'Sales',
            filter_period:'WTD',
            filter_period_start:null,
            filter_period_end:null,
        },

        seriesCoswick: [{name:'Продажи , м²', data: []}],

        chartOptionsCoswick: {},

        all_region:0, //По всем регионам

    }),
    computed:{
        ordersSales(){
            // return this.sales_list ? this.sales_list.sort((a, b) => (a.Region || "я").localeCompare((b.Region || "я"))) : []
            return this.sales_list ? this.sales_list.sort((a, b) => (a.Region || "я").localeCompare((b.Region || "я"))) : []
        },
        filterSales(){
            if(this.filter_region_sign) {
                return this.sales_list.filter(region => (region.IDRegion === this.filter_region) )[0]
            }

            if(this.filter_counterparty) {
                return (this.filter_counterparty) ? this.sales_list_counterparty.filter(counterparty =>
                    (counterparty.IDKontragent.includes(this.filter_counterparty)) )[0] : null
            }
        },
        filterSalesDetailsOrders(){
            if(this.filter_region_sign){
                return this.sales_list_details.filter(region =>
                    (region.IDRegion === this.filter_region && region.TIPV.toUpperCase() === this.ORDERS.toUpperCase()))[0]
            }

            if(this.filter_counterparty){
                return (this.filter_counterparty) ? this.sales_list_counterparty_details.filter(counterparty =>
                    (counterparty.IDKontragent.includes(this.filter_counterparty) && counterparty.TIPV.toUpperCase() === this.ORDERS.toUpperCase()))[0] : null
            }
        },
        filterSalesDetailsSales(){
            if(this.filter_region_sign) {
                return this.sales_list_details.filter(region => (region.IDRegion === this.filter_region
                    && region.TIPV.toUpperCase() === this.SALES.toUpperCase()) )[0]
            }

            if(this.filter_counterparty) {
                return (this.filter_counterparty) ? this.sales_list_counterparty_details.filter(counterparty =>
                    (counterparty.IDKontragent.includes(this.filter_counterparty) && counterparty.TIPV.toUpperCase() === this.SALES.toUpperCase()))[0] : null
            }
        },

        filterCounterpartySales(){
            return this.sales_list_counterparty.filter(region =>
                ((this.filter_region ? region.IDRegion.includes(this.filter_region) : region.IDRegion !== '')
                    && region.KolichestvoFact !== 0))
                .sort((a, b) => b.KolichestvoFact - a.KolichestvoFact || 0)
        },
    },
    created() {
        this.search()
    },
    methods:{
        search(){
            this.period(this.formFilter)

            this.preloaderShow()
            this.sales_list = []
            this.sales_list_details = []
            this.sales_list_counterparty = []
            Object.assign(this.$data.seriesCoswick, this.$options.data().seriesCoswick)

            axios.post('/analytics/search-sales',this.formFilter).then(response => {
                this.sales_list = response.data['data']
                for (let i = 0; i < this.sales_list.length; i++) {
                    this.sales_list[i]['ProcentPrirosta'] = this.procentPrirosta(this.sales_list[i])
                }

                this.all_region = Array.from(this.sales_list.filter(x => x.IDRegion === '')
                        .reduce((m, {KolichestvoFact}) =>
                            m.set(KolichestvoFact), new Map),
                    ([KolichestvoFact]) => ({KolichestvoFact})
                );

                let ordersSalesCoswick = this.ordersSales.filter(x => x.Region !== '')
                this.seriesCoswick[0] = {
                    name: this.formFilter.filter_category === this.SALES ? 'Продажи, м²' : 'Заказы м²',
                    // data: ordersSalesCoswick.map(sales => sales.KolichestvoFact),
                    data: ordersSalesCoswick.map((key) => ({x:'', y:key.KolichestvoFact,
                        goals: [{
                            name: 'План ' + key.Region + ', м²:',
                            value: key.KolichestvoPlan || 0,
                            strokeWidth: 2,
                            strokeDashArray: 3,
                            strokeColor: '#919bae'
                        }]
                    })),
                }

                this.chartOptionsCoswick = {
                    chart: {
                        type: 'bar',
                        height: ordersSalesCoswick.length < 3 ? 180 : (ordersSalesCoswick.length < 5 ? 260 : 380),
                        toolbar: {
                            show: false
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            borderRadius: 4,
                            dataLabels: {position: 'bottom'},
                        }
                    },
                    // colors: ['#69d2e7'],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            fontSize: '12px',
                            colors: ['#b0b8bf'],
                            fontWeight: '200',
                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + ' м²'
                        },
                        offsetX: 0,
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        width: 0.5,
                        colors: ['#fff']
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                    },
                    xaxis: {
                        categories: ordersSalesCoswick.map(sales => sales.Region),
                    },
                }

            }).catch(error => {
                // console.log(error);
            }).finally(() => {
                this.preloaderClose()
                this.searchCounterpartySales()
            })
            this.searchSalesDetails()
        },

        filterDetailsSales(sales){
            this.scrollTop(".offcanvas-body")
            this.filter_region_sign = true
            this.filter_region = sales.IDRegion
            this.filter_counterparty = null
        },
        counterpartySales(sales){
            this.scrollTop(".offcanvas-body")
            this.filter_region_sign = false
            // this.filter_region = null
            this.filter_counterparty = sales.IDKontragent
        },

        searchCounterpartySales(){
            axios.post('/analytics/counterparty-sales',this.formFilter).then(response => {
                this.sales_list_counterparty = response.data['data'] || []
            }).catch(error => {
                // console.log(error);
            }).finally(()=>{
                this.searchCounterpartySalesDetails()
            })
        },
        async searchCounterpartySalesDetails(){
            axios.post('/analytics/counterparty-sales-details',this.formFilter).then(response => {
                this.sales_list_counterparty_details = response.data['data'] || []
            }).catch(error => {
                // console.log(error);
            })
        },

        async searchSalesDetails(){
            axios.post('/analytics/search-sales-details',this.formFilter).then(response => {
                this.sales_list_details = response.data['data'] || []
            }).catch(error => {
                // console.log(error);
            })
        },

        specificGravity(val){
            let result = ((val.KolichestvoFact / this.all_region[0]['KolichestvoFact'])*100).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1')
            return (isNaN(result)) ? 0 : result
        },

        deviationColor(val){
            return val > 0 ? 'text-success' : val === 0 ? 'text-warning' : 'text-danger'
        },

        procentPrirosta(os){
            return parseFloat((os.KolichestvoFact > 0 && os.KolichestvoFactPred > 0
                ? ((os.KolichestvoFact - os.KolichestvoFactPred) / os.KolichestvoFactPred * 100)
                : ( os.KolichestvoFact > 0 && os.KolichestvoFactPred === 0 ? 100
                    : ( os.KolichestvoFact === 0 && os.KolichestvoFactPred > 0 ? -100 : 0))).toFixed(1))
        }

        // chartHeight(val){
        //     return val === 1 ? 100 : (val === 2 ? 180 : (val === 3 ? 260 : (val === 4 ? 340 : 420)))
        // }
    }
}
</script>

<style scoped>
</style>
